import { Grid, Stack, styled } from '@mui/material';

export const ProductContainer = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  gap: '1rem',
  paddingTop: '1rem',
}));

export const ProductImage = styled('img')(() => ({
  width: '5.25rem',
  height: '5.25rem',
  borderRadius: '1rem',
}));

export const ProductData = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  width: '100%',
}));

export const ProductDataStack = styled(Stack)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  width: '100%',
}));

export const ProductOptions = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
}));

export const ProductQuantity = styled(Stack)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  minWidth: '110px',
  minHeight: '2rem',
  borderRadius: '0.25rem',
  border: '0.5px solid #D9D9D9',
  gap: '0.5rem',
}));
