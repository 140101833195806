import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Typography, Stack } from '@mui/material';
import useTrack from '../../hooks/useTrack';
import { StepContinueButton } from './stepscheckout.styled';

function ReturnToStore() {
  const { t } = useTranslation(['order-complete']);
  const order = useSelector((state) => state.orders);
  const redirectLink = useSelector((state) => state.redirectLink);
  const currentLink = redirectLink || order.redirect_link || order.store?.store_url;
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(30);
  const timer = `0${minutes}:${seconds >= 10 ? seconds : `0${seconds}`}`;

  const handleReturnToStore = () => {
    window.parent.location.href = decodeURIComponent(currentLink);
    useTrack('btn_regresar_tienda', {
      pantalla: 'Paso 5 - Orden completa',
      merchant: order?.store?.store_name,
    });
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (minutes > 0 && seconds === 0) {
        setMinutes(minutes - 1);
        setSeconds(59);
      }
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (minutes === 0 && seconds === 0) {
        setMinutes(0);
        setSeconds(30);
        handleReturnToStore();
      }
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [seconds]);

  return (
    <Stack direction="column" justifyContent="center" alignItems="center" gap={1}>
      <Stack direction="column" justifyContent="center" alignItems="center">
        <Typography variant="body1">
          {t('redirect-timer')}
        </Typography>
        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
          {timer}
        </Typography>
      </Stack>
      <StepContinueButton
        variant="contained"
        sx={{ width: 'max-content' }}
        onClick={() => handleReturnToStore()}
      >
        {t('redirect-now')}
      </StepContinueButton>
    </Stack>

  );
}

export default ReturnToStore;
