import React from 'react';
import { Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DialogContentStyled, DialogStyled, ModalStack } from './modal.styled';
import failureIcon from '../../assets/payment_failure_icon.svg';

export default function PaymentFailureModal({ open, redirect }) {
  const { t } = useTranslation(['modals']);

  const handleRedirect = () => {
    if (redirect) {
      window.parent.location.href = redirect;
    }
  };

  return (
    <DialogStyled open={open}>
      <DialogContentStyled
        sx={{
          padding: '2rem',
          justifyContent: 'center',
          alignItems: 'flex-start',
        }}
      >
        <ModalStack>
          <img src={failureIcon} alt="Failure icon" width="48px" />
          <Typography variant="h5">
            {t('Tu pago ha sido rechazado')}
          </Typography>
          <Typography variant="body2">
            {t('Ha ocurrido un error y tu pago no ha sido procesado. Elige una forma de pago diferente o intenta de nuevo para confirmar esta compra.')}
          </Typography>
        </ModalStack>
        <ModalStack width="100%" sx={{ gap: '1.5rem' }}>
          <Button
            sx={{
              px: '2rem',
              borderRadius: '6.25rem',
              textTransform: 'none',
              alignSelf: 'center',
            }}
            variant="contained"
            size="large"
            onClick={() => window.location.reload()}
          >
            {t('Intentar con otra forma de pago')}
          </Button>
          <Button
            sx={{
              display: redirect ? 'flex' : 'none',
              px: '2rem',
              borderRadius: '6.25rem',
              textTransform: 'none',
              alignSelf: 'center',
            }}
            variant="outlined"
            size="large"
            onClick={() => handleRedirect()}
          >
            {t('Regresar a la tienda')}
          </Button>
        </ModalStack>
      </DialogContentStyled>
    </DialogStyled>
  );
}
