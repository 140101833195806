/* eslint-disable object-shorthand */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/jsx-indent */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  CircularProgress,
  Grid,
  InputAdornment,
  Skeleton,
  Typography,
  styled,
  useMediaQuery,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Country } from 'country-state-city';
import Flag from 'react-world-flags';
import { FormStyled, SelectInputStyled, TextInputStyled } from '../stepscheckout.styled';
// import { login } from '../../../redux/actions/authAction';
import COUNTRIES from '../../../utils/countries';
import useTrack from '../../../hooks/useTrack';
import {
  emailPattern, handleEmailRegex, handleNumericRegex, handleTextRegex,
} from '../../../utils/regex';
import { getAllowedDocuments } from '../../../redux/actions/nationalDocumentsActions';

const CountrySelect = styled('select')(() => ({
  width: '1rem',
  height: '2.5rem',
  backgroundCOlor: 'red',
  borderRadius: '20px',
  border: 'none',
  outline: '0px',
  cursor: 'pointer',
}));

function PaymentForm({
  paramsData, emptyCart, setUserDataSaved,
}) {
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state) => state.user);
  const allowedDocuments = useSelector((state) => state.user.allowedDocuments);
  const currentUser = useSelector((state) => state.user);
  const order = useSelector((state) => state.orders);
  const storeName = order?.store?.store_name;
  const geo = localStorage.getItem('geo');
  const loadingState = useSelector((state) => state.loading);
  const { t } = useTranslation(['user-info']);
  const countries = Country.getAllCountries();
  const isMobile = useMediaQuery('(max-width:650px)');
  const [selectedCountryCode, setSelectedCountryCode] = useState(
    (currentUser?.phone_number_country_code
      || (currentUser?.country?.iso_code
        ? currentUser.country?.iso_code : '')) || geo,
  );
  const phoneCountry = selectedCountryCode ? Object.keys(COUNTRIES).find(
    (cCode) => COUNTRIES[cCode].id === selectedCountryCode,
  ) : (geo || 'CO');
  const phoneDialCode = selectedCountryCode ? COUNTRIES[selectedCountryCode]?.dialCode : '+57';

  const hideComponent = paramsData?.email && paramsData?.phone_number
    && paramsData?.first_name && paramsData?.last_name
    && ((paramsData?.id_national_document_type && paramsData?.national_id_number)
      || (paramsData?.id_type && paramsData?.id_number));

  const [email, setEmail] = useState(currentUser?.email || paramsData?.email || '');
  const [phoneNumber, setPhoneNumber] = useState(currentUser?.phone_number || paramsData?.phone_number || '');
  const [firstName, setFirstName] = useState(currentUser?.first_name || paramsData?.first_name || '');
  const [lastName, setLastName] = useState(currentUser?.last_name || paramsData?.last_name || '');
  const [idDocumentType, setIdDocumentType] = useState(currentUser?.id_national_document_type || paramsData?.id_national_document_type || paramsData?.id_type || '');
  const [nationalIdNumber, setNationalIdNumber] = useState(currentUser?.national_id_number || paramsData?.national_id_number || paramsData?.id_number || '');

  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [idDocumentTypeError, setIdDocumentTypeError] = useState(false);
  const [nationalIdNumberError, setNationalIdNumberError] = useState(false);

  const [values, setValues] = useState({
    phone_number: currentUser?.phone_number || paramsData?.phone_number || '',
    phone_number_country_code: currentUser?.phone_number_country_code
      || (currentUser?.country?.iso_code
        ? currentUser.country?.iso_code : ''),
    email: currentUser?.email || paramsData?.email || '',
    first_name: currentUser?.first_name || paramsData?.first_name || '',
    last_name: currentUser?.last_name || paramsData?.last_name || '',
    id_national_document_type: currentUser?.id_national_document_type || paramsData?.id_national_document_type || paramsData?.id_type || '',
    national_id_number: currentUser?.national_id_number || paramsData?.national_id_number || paramsData?.id_number || '',
  });

  useEffect(() => {
    dispatch(getAllowedDocuments(geo));
    useTrack('payment_form', {
      pantalla: 'Payment checkout',
      merchant: storeName,
    });
  }, []);

  const checkActiveUser = () => {
    if (!isAuthenticated) {
      if (handleEmailRegex(email) && email !== '') {
        // dispatch(login({ email: email, id_store: paramsData?.id_store }));
        setEmailError(false);
      } else {
        setEmailError(true);
        useTrack('error_input', {
          pantalla: 'Payment checkout',
          merchant: storeName,
          elemento: 'Email input',
          tipo: 'Regex incorrecta',
        });
      }
    }
  };

  useEffect(() => {
    setUserDataSaved(false);
    if (email !== '' && phoneNumber !== ''
      && firstName !== '' && lastName !== ''
      && idDocumentType !== '' && nationalIdNumber !== ''
      && !emailError) {
      setUserDataSaved(true);
      dispatch({
        type: 'SET_USER_DATA',
        payload: {
          ...values,
          email: email?.trim()?.replace(/ /g, ''),
          phone_number: phoneNumber?.trim()?.replace(/ /g, ''),
          phone_number_country_code: phoneCountry?.trim(),
          phone_number_country_number: phoneDialCode?.trim(),
          first_name: firstName?.trim(),
          last_name: lastName?.trim(),
          id_national_document_type: idDocumentType,
          national_id_number: nationalIdNumber?.trim()?.replace(/ /g, ''),
        },
      });
      dispatch({
        type: 'SET_PREORDER_SHOPPER',
        payload: {
          ...values,
          email: email?.trim()?.replace(/ /g, ''),
          phone_number: phoneNumber?.trim()?.replace(/ /g, ''),
          phone_number_country_code: phoneCountry?.trim(),
          phone_number_country_number: phoneDialCode?.trim(),
          first_name: firstName?.trim(),
          last_name: lastName?.trim(),
          id_national_document_type: idDocumentType,
          national_id_number: nationalIdNumber?.trim()?.replace(/ /g, ''),
        },
      });
    }
    setValues({
      ...values,
      email: email?.trim()?.replace(/ /g, ''),
      phone_number: phoneNumber?.trim()?.replace(/ /g, ''),
      phone_number_country_code: phoneCountry?.trim(),
      phone_number_country_number: phoneDialCode?.trim(),
      first_name: firstName?.trim(),
      last_name: lastName?.trim(),
      id_national_document_type: idDocumentType,
      national_id_number: nationalIdNumber?.trim()?.replace(/ /g, ''),
    });
    if (phoneNumber !== '' && phoneError) {
      setPhoneError(false);
    }
    if (firstName !== '' && firstNameError) {
      setFirstNameError(false);
    }
    if (lastName !== '' && lastNameError) {
      setLastNameError(false);
    }
    if (idDocumentType !== '' && idDocumentTypeError) {
      setLastNameError(false);
    }
    if (nationalIdNumber !== '' && nationalIdNumberError) {
      setNationalIdNumberError(false);
    }
  }, [
    email,
    phoneNumber,
    phoneDialCode,
    phoneCountry,
    firstName,
    lastName,
    idDocumentType,
    nationalIdNumber,
    emailError,
  ]);

  return (
    <FormStyled sx={hideComponent ? { display: 'none' } : { marginTop: '1rem' }}>
      <Typography variant="h6" sx={{ paddingBottom: '1.5rem' }}>
        {t('first-step-title')}
      </Typography>
      <Box sx={{ display: 'grid', columnGap: 2, rowGap: 3 }}>
        <TextInputStyled
          type="email"
          label={t('email_label')}
          placeholder={t('email_ph')}
          value={email}
          error={emailError}
          helperText={emailError ? t('email_required') : undefined}
          disabled={emptyCart}
          pattern={emailPattern}
          onBlur={() => checkActiveUser()}
          onChange={(e) => setEmail(e.target.value)}
          sx={paramsData?.email ? { display: 'none' } : {}}
          InputLabelProps={{ shrink: true }}
          InputProps={loadingState ? {
            endAdornment:
              <InputAdornment
                position="end"
                sx={loadingState ? { display: 'flex' } : { display: 'none' }}
              >
                <CircularProgress size="1.5rem" />
              </InputAdornment>,
          } : null}
        />
        <TextInputStyled
          type="text"
          label={t('phone_label')}
          placeholder={t('phone_ph')}
          value={phoneNumber}
          error={phoneError}
          helperText={phoneError ? t('phone_required') : undefined}
          disabled={emptyCart}
          onChange={(e) => handleNumericRegex(e.target.value, setPhoneNumber)}
          sx={paramsData?.phone_number ? { display: 'none' } : {}}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            startAdornment:
              <Grid sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                width: '3rem',
                marginRight: (phoneCountry && phoneDialCode) ? '3rem' : '0.5rem',
                gap: '0.5rem',
              }}
              >
                {phoneCountry
                  && (
                    <Flag
                      code={phoneCountry}
                      width="30"
                      fallback=""
                    />
                  )}
                <CountrySelect
                  disabled={emptyCart}
                  sx={emptyCart ? { cursor: 'default' } : { cursor: 'pointer' }}
                  onChange={(e) => setSelectedCountryCode(e.target.value)}
                >
                  <option value="" style={{ display: 'none' }} />
                  {countries.map((country) => (
                    <option
                      key={country.isoCode}
                      value={country.isoCode}
                    >
                      {country.name}
                    </option>
                  ))}
                </CountrySelect>
                {phoneDialCode
                  && (
                    <strong>
                      {`${phoneDialCode}`}
                    </strong>
                  )}
              </Grid>,
          }}
        />
        <Grid
          direction={isMobile ? 'column' : 'row'}
          container
          justifyContent="space-between"
          gap={2}
          sx={(paramsData?.first_name && paramsData?.last_name) ? { display: 'none' } : {}}
        >
          <TextInputStyled
            type="text"
            label={t('first_name_label')}
            placeholder={t('first_name_ph')}
            value={firstName}
            error={firstNameError}
            helperText={firstNameError ? t('first_name_required') : undefined}
            disabled={emptyCart}
            onChange={(e) => handleTextRegex(e.target.value, setFirstName)}
            InputLabelProps={{ shrink: true }}
            sx={{ display: 'flex', flex: 1 }}
          />
          <TextInputStyled
            type="text"
            label={t('last_name_label')}
            placeholder={t('last_name_ph')}
            value={lastName}
            error={lastNameError}
            helperText={lastNameError ? t('last_name_required') : undefined}
            disabled={emptyCart}
            onChange={(e) => handleTextRegex(e.target.value, setLastName)}
            InputLabelProps={{ shrink: true }}
            sx={{ display: 'flex', flex: 1 }}
          />
        </Grid>
        {currentUser?.loadingUserData
          ? <Skeleton sx={{ height: '3.5rem', borderRadius: '10px' }} variant="rounded" animation="wave" />
          : (allowedDocuments.length > 0
            && (
              <Grid
                direction="row"
                container
                justifyContent="space-between"
                spacing={3}
                sx={(paramsData?.id_national_document_type && paramsData?.national_id_number)
                  || (paramsData?.id_type && paramsData?.id_number)
                  ? { display: 'none' } : {}}
              >
                <Grid direction="column" container item xs={12} sm={5}>
                  <SelectInputStyled
                    id="id_national_document_type"
                    label={t('id_type_label')}
                    placeholder={t('id_type_label')}
                    value={idDocumentType}
                    error={idDocumentTypeError}
                    helperText={idDocumentTypeError ? t('id_type_required') : undefined}
                    select
                    InputLabelProps={{ shrink: true, required: false }}
                    SelectProps={{ native: true }}
                    onChange={(e) => setIdDocumentType(e.target.value)}
                    sx={{ width: '100%' }}
                  >
                    <option value="" style={{ display: 'none' }}>
                      {t('id_type_label')}
                    </option>
                    {allowedDocuments.map((document) => (
                      <option
                        key={document.type}
                        value={document.id_national_document_type}
                      >
                        {document.type}
                      </option>
                    ))}
                  </SelectInputStyled>
                </Grid>
                <Grid direction="column" container item xs={12} sm={7}>
                  {allowedDocuments?.length > 0
                    ? (
                      <TextInputStyled
                        type="text"
                        label={t('id_number_label')}
                        placeholder={t('id_number_label')}
                        error={nationalIdNumberError}
                        helperText={nationalIdNumberError ? t('id_number_required') : undefined}
                        InputLabelProps={{ shrink: true }}
                        id="national_id_number"
                        value={nationalIdNumber}
                        onChange={(e) => setNationalIdNumber(e.target.value)}
                        sx={{ width: '100%' }}
                      />
                    )
                    : (
                      <TextInputStyled
                        name="national_id_number"
                        label={t('id_number_label')}
                        sx={{ width: '100%' }}
                        disabled
                      />
                    )}
                </Grid>
              </Grid>
            ))}
      </Box>
    </FormStyled>

  );
}

export default PaymentForm;
