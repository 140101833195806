import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Grid, Typography, Stack, styled, useMediaQuery,
} from '@mui/material';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import Flag from 'react-world-flags';
import LoadingScreen from '../../LoadingScreen';
import PaymentsIcon from '../../../assets/PaymentsIcon';
import CheckCircleIcon from '../../../assets/CheckCircleIcon';
import { SpanStyled } from '../stepscheckout.styled';
import { ConfirmationCard, ConfirmationContainer, OrderCompleteContainer } from './ordercomplete.styled';
import COUNTRIES from '../../../utils/countries';
import { fCurrency } from '../../../utils/formatNumber';
import ReturnToStore from '../ReturnToStore';
import useTrack from '../../../hooks/useTrack';

const PaymentMethod = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderRadius: 1,
}));

function OrderCompleteStep() {
  const { t } = useTranslation(['order-complete']);
  const user = useSelector((state) => state.user);
  const order = useSelector((state) => state.orders);
  const preorderData = useSelector((state) => state.preorder);
  const shippingAddress = useSelector((state) => state.preorder.shipping_address);
  const { confirmation } = useSelector((state) => state?.orders);
  const isMobile = useMediaQuery('(max-width:780px)');

  const [creditCard, setCreditCard] = useState(user.isAuthenticated
    ? user.payment_methods[0]?.credit_cards?.find((cc) => cc.is_default === 1)
    : user?.payment_methods[0]?.credit_cards[0]);

  const cardBrand = creditCard?.brand
    && creditCard?.brand[0]?.toUpperCase().concat(creditCard?.brand?.slice(1).toLowerCase());
  const lastFour = creditCard?.last_four && `•••• ${creditCard.last_four}`;

  const date = new Date(Date.now()).toLocaleDateString('en-GB', {
    weekday: 'short', year: 'numeric', month: 'long', day: 'numeric',
  });

  useEffect(() => {
    sessionStorage.removeItem('cc');
    sessionStorage.removeItem('billing');
    setCreditCard(
      user.isAuthenticated
        ? user.payment_methods[0]?.credit_cards?.find((cc) => cc.is_default === 1)
        : user.payment_methods[0]?.credit_cards[0],
    );
    useTrack('orden_completa', {
      pantalla: 'Order complete',
      merchant: order?.store?.store_name,
    });
  }, []);

  return (
    confirmation?.loading
      ? <LoadingScreen />
      : (
        <OrderCompleteContainer>
          <ConfirmationContainer>
            <CheckCircleIcon />
            <Typography variant="h4">
              {t('heading-1')}
            </Typography>
            <Stack textAlign="center">
              <Typography variant="body1">
                {t('body-1')}
                <SpanStyled bold="true">
                  &nbsp;
                  {user.email}
                </SpanStyled>
              </Typography>
            </Stack>
          </ConfirmationContainer>

          <ConfirmationCard>
            <Stack spacing={1}>
              <Typography variant="h5">
                {t('order-title', { orderId: confirmation?.id_order })}
              </Typography>
              <Typography variant="body2">
                {date}
              </Typography>
            </Stack>
            <Stack spacing={1} sx={order.isDigital ? { display: 'none' } : {}}>
              <Typography variant="h5">
                {t('shipment-title')}
              </Typography>
              <Stack direction="row" spacing={1}>
                <Flag
                  code={
                    Object.keys(COUNTRIES).find(
                      // eslint-disable-next-line max-len
                      (country) => COUNTRIES[country]?.label?.toLowerCase() === shippingAddress?.country_name?.toLowerCase(),
                    )
                  }
                  height="20"
                />
                <Typography variant="body2">
                  {shippingAddress?.address_field_1}
                  {', '}
                  {shippingAddress?.city_name}
                </Typography>
              </Stack>
            </Stack>
            <Stack spacing={1}>
              <Typography variant="h5">
                {t('payment-title')}
              </Typography>
              {confirmation?.payment_method?.credit_card?.gateway === 'stripe'
                && (
                  <Stack direction="column" gap={1}>
                    <Stack direction="row" gap={1} alignItems="center">
                      <PaymentsIcon brand={creditCard?.brand} width="30px" height="30px" />
                      <Stack direction="row" gap={1}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                          {cardBrand}
                          {' '}
                          {lastFour}
                        </Typography>
                      </Stack>
                    </Stack>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                      {`Total: ${fCurrency(user.isAuthenticated ? order.total_price : preorderData.total_price)}`}
                    </Typography>
                  </Stack>
                )}
              {Boolean(confirmation?.payment_method?.nequi)
                && (
                  <PaymentMethod>
                    <Grid container direction="row" gap="1rem" alignItems="center">
                      <PaymentsIcon brand="nequi" width="30px" height="30px" />
                      <Stack direction="column">
                        <Typography variant="body2" sx={{ color: 'text.primary' }}>
                          Nequi
                        </Typography>
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                          Pago desde la app
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid container direction="row" gap="1rem" alignItems="center" justifyContent="end">
                      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        {confirmation?.payment_method?.nequi?.phone_number}
                      </Typography>
                    </Grid>
                  </PaymentMethod>
                )}
              {confirmation?.payment_method?.credit_card?.gateway === 'Bank Transfer'
                && (
                  <PaymentMethod>
                    <Grid container direction="row" gap="1rem" alignItems="center">
                      <AccountBalanceIcon />
                      <Typography variant="body2" sx={{ color: 'text.primary' }}>
                        {t('bank-transfer')}
                      </Typography>
                    </Grid>
                  </PaymentMethod>
                )}
              {confirmation?.payment_method?.credit_card?.gateway === 'Cash'
                && (
                  <PaymentMethod>
                    <Grid container direction="row" gap="1rem" alignItems="center">
                      <MonetizationOnIcon />
                      <Stack direction="column">
                        <Typography variant="body2" sx={{ color: 'text.primary' }}>
                          {t('payment-cash')}
                        </Typography>
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                          {t('payment-delivery')}
                        </Typography>
                      </Stack>
                    </Grid>
                  </PaymentMethod>
                )}
            </Stack>
          </ConfirmationCard>
          {!isMobile && <ReturnToStore />}
        </OrderCompleteContainer>
      )
  );
}

export default OrderCompleteStep;
