import React from 'react';
import {
  IconButton, Stack, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Close } from '@mui/icons-material';
import {
  DialogContentStyled, DialogStyled,
} from './modal.styled';
import Summary from '../StepsCheckout/Summary/Summary';

function SummaryModal({ open, handleModal, paymentCheckout }) {
  const { t } = useTranslation(['modals']);

  return (
    <DialogStyled open={open} onClose={() => handleModal(false)} hideBackdrop>
      <DialogContentStyled sx={{ gap: '0rem !important' }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="h6">
            {t('modal-summary-title')}
          </Typography>
          <IconButton onClick={() => handleModal(false)}>
            <Close />
          </IconButton>
        </Stack>
        <Summary currentStep={10} fromModal={1} paymentCheckout={paymentCheckout} />
      </DialogContentStyled>
    </DialogStyled>
  );
}

export default SummaryModal;
