/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import { CircularProgress, Typography } from '@mui/material';
import Iconify from '../Iconify/Iconify';
import AuthCode from '../Login/AuthCode';
import { loginCode, loginResendCode } from '../../redux/actions/authAction';
import {
  ArrowContainer,
  CodeLoaderContainer,
  CodeStepCard,
  CodeStepContainer,
  OpacityContainer,
  SpanStyled,
} from './stepscheckout.styled';

function CodeStep() {
  const { t } = useTranslation(['code']);
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const codeError = false;
  const [timer, setTimer] = useState(60);

  const user = useSelector((state) => state.user);
  const loadingUserData = useSelector((state) => state.user.loadingUserData);

  const hiddenEmail = user.email.slice(0, 3).concat('•••').concat(user.email.slice(user.email.indexOf('@')));

  useEffect(() => {
    if (!user?.email) {
      dispatch({
        type: 'SET_CHECKOUT_STEP',
        payload: 1,
      });
    }
  }, [user]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      timer > 0 && setTimer(timer - 1);
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [timer]);

  const handleOnChange = (code) => {
    if (code.length === 4) {
      dispatch(
        loginCode(
          { email: user.email, logincode: code },
          { msg_error: t('notification-negative') },
        ),
      );
    }
  };

  const onResend = () => {
    setIsSubmitting(true);
    setTimer(60);
    dispatch(loginResendCode(
      { email: user.email },
      {
        msg_positive: t('resend-notification-positive'),
        msg_negative: t('resend-notification-negative'),
      },
    ));
    setIsSubmitting(false);
  };

  const handleBack = () => {
    dispatch({
      type: 'SET_CHECKOUT_STEP',
      payload: 1,
    });
  };

  return (
    <CodeStepContainer>
      <CodeStepCard>
        <OpacityContainer sx={loadingUserData ? { opacity: 0.3 } : { opacity: 1 }}>
          <Typography variant="h5">
            {t('account-found')}
          </Typography>
          <Typography variant="p">
            {t('to-continue')}
            <SpanStyled bold="true">
              &nbsp;
              {hiddenEmail}
              &nbsp;
            </SpanStyled>
            <SpanStyled link="true" onClick={() => handleBack()}>
              {t('change-email')}
            </SpanStyled>
          </Typography>
          <AuthCode stepCheckout="true" onChange={handleOnChange} error={codeError} />
          {codeError && (
            <Typography
              align="center"
              color="error"
              variant="body2"
            >
              {t('notification-negative')}
            </Typography>
          )}
          <Typography variant="body2">
            {t('reminder')}
          </Typography>
        </OpacityContainer>
        {loadingUserData
          ? (
            <CodeLoaderContainer>
              <CircularProgress color="primary" />
            </CodeLoaderContainer>
          )
          : (
            <LoadingButton
              size="large"
              fullWidth
              type="submit"
              variant="contained"
              loading={isSubmitting}
              color="primary"
              disabled={timer > 0}
              onClick={() => onResend()}
            >
              {t('button') + (timer ? ` 00:${timer < 10 ? `0${timer}` : timer}` : '')}
            </LoadingButton>
          )}
      </CodeStepCard>

      <ArrowContainer onClick={() => handleBack()}>
        <Iconify icon="eva:arrow-back-outline" style={{ width: '22px', height: '22px' }} />
        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
          {t('return')}
        </Typography>
      </ArrowContainer>

    </CodeStepContainer>

  );
}

export default CodeStep;
