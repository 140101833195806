import { api, apiGet, apiPatch } from '../../utils/api';
// import { userData } from './userActions';

export const orders = (data) => (dispatch) => {
  dispatch({
    type: 'SET_ORDER_LOADING',
    payload: true,
  });
  return api({
    url: 'orders',
    data,
    successHandler: (successData) => {
      dispatch({
        type: 'SET_ORDER',
        payload: successData?.result,
      });
      dispatch({
        type: 'SET_ORDER_LOADING',
        payload: false,
      });
    },
    errorHandler: (error) => {
      if (error.response?.data?.result?.message === 'Store inactive') {
        dispatch({
          type: 'SET_INACTIVE_STORE',
          payload: true,
        });
      } else {
        dispatch({
          type: 'SET_INACTIVE_STORE',
          payload: false,
        });
      }
      dispatch({
        type: 'SET_ORDER_LOADING',
        payload: false,
      });
    },
  });
};

export const placeOrder = (orderId, data, localization, gatewayName) => (dispatch) => {
  dispatch({
    type: 'PLACE_ORDER',
    payload: true,
  });
  return api({
    url: `orders/place/${orderId}`,
    data,
    successHandler: (successData) => {
      dispatch({
        type: 'SET_ORDER_CONFIRMED',
        payload: successData?.result,
      });
      dispatch({
        type: 'PLACE_ORDER',
        payload: false,
      });
      if (successData?.result?.token_required) {
        dispatch({
          type: 'SET_TOKEN_REQUIRED',
          payload: true,
        });
      } else if (gatewayName === 'Nequi') {
        dispatch({
          type: 'SET_CHECKOUT_STEP',
          payload: 6,
        });
      } else {
        dispatch({
          type: 'SET_CHECKOUT_STEP',
          payload: 5,
        });
        dispatch({
          type: 'SET_MESSAGE',
          payload: { text: localization.msg_success || 'Order Processed!', kind: 'success' },
        });
      }
    },
    errorHandler: (error) => {
      if (error === 'Invalid Confirmation token') {
        dispatch({
          type: 'SET_TOKEN_ERROR',
          payload: true,
        });
        dispatch({
          type: 'SET_ORDER_FAILED',
          payload: data?.result,
        });
      } else {
        dispatch({
          type: 'SET_MESSAGE',
          payload: { text: error?.response?.data?.result?.message, kind: 'error' },
        });
        dispatch({
          type: 'SET_ORDER_FAILED',
          payload: data?.result,
        });
      }
      dispatch({
        type: 'PLACE_ORDER',
        payload: false,
      });
    },
  });
};

export const getOrderStatus = (orderId, localization, failureModal) => (dispatch) => {
  dispatch({
    type: 'PLACE_ORDER',
    payload: true,
  });
  return apiGet({
    url: `orders/${orderId}`,
    successHandler: (successData) => {
      dispatch({
        type: 'PLACE_ORDER',
        payload: false,
      });
      if (successData?.result?.status === 'confirmed') {
        dispatch({
          type: 'SET_MESSAGE',
          payload: { text: localization.msg_success || 'Order Processed!', kind: 'success' },
        });
        dispatch({
          type: 'SET_ORDER_CONFIRMED',
          payload: successData?.result,
        });
        dispatch({
          type: 'SET_CHECKOUT_STEP',
          payload: 5,
        });
      } else if (successData?.result?.status === 'pending') {
        dispatch({
          type: 'SET_MESSAGE',
          payload: { text: localization.msg_pending || 'Payment pending.', kind: 'warning' },
        });
      } else if (successData?.result?.status === 'processing') {
        dispatch({
          type: 'SET_MESSAGE',
          payload: { text: localization.msg_processing || 'Your payment is being processed.', kind: 'warning' },
        });
      } else if (successData?.result?.status === 'cancelled') {
        failureModal(true);
      } else {
        dispatch({
          type: 'SET_MESSAGE',
          payload: { text: 'Unhandled Error', kind: 'error' },
        });
      }
    },
    errorHandler: (error) => {
      failureModal(true);
      dispatch({
        type: 'SET_MESSAGE',
        payload: { text: error?.response?.data?.result?.message, kind: 'error' },
      });
      dispatch({
        type: 'PLACE_ORDER',
        payload: false,
      });
    },
  });
};

export const getOrders = (storeId = '', range) => (dispatch) => {
  dispatch({
    type: 'SET_ORDER_LOADING',
    payload: true,
  });
  return apiGet({
    url: `orders${storeId
      ? (range
        ? `/merchant/${storeId}?date_from=${range.from}&date_to=${range.to}&limit=100&sort=purchase_date`
        : `/merchant/${storeId}?limit=100&sort=purchase_date`)
      : ''}`,
    successHandler: (data) => {
      if (data) {
        dispatch({
          type: 'SET_ORDERS_LIST',
          payload: data?.result,
        });
        dispatch({
          type: 'SET_FILTERED_ORDERS',
          payload: data?.result,
        });
      } else {
        dispatch({
          type: 'SET_MESSAGE',
          payload: { text: 'No orders available', kind: 'error' },
        });
      }
      dispatch({
        type: 'SET_ORDER_LOADING',
        payload: false,
      });
    },
    errorHandler: (error) => {
      dispatch({
        type: 'SET_MESSAGE',
        payload: { text: error?.result, kind: 'error' },
      });
      dispatch({
        type: 'SET_ORDER_LOADING',
        payload: false,
      });
    },
  });
};

// export const getCheckoutOrder = (localization, navigate, reduxLocations) => (dispatch) => {
//   dispatch({
//     type: 'SET_ORDER_LOADING',
//     payload: true,
//   });
//   return apiGet({
//     url: 'orders?status=pending',
//     successHandler: (data) => {
//       if (data?.result?.length === 0) {
//         if (Object.keys(reduxLocations).length > 0) {
//           dispatch({
//             type: 'SET_MESSAGE',
//             payload: { text: localization.msg_error || 'No open orders', kind: 'error' },
//           });
//         }
//         // navigate('/');
//       }
//       dispatch({
//         type: 'SET_ORDER',
//         payload: data?.result[0],
//       });
//       dispatch({
//         type: 'SET_ORDER_LOADING',
//         payload: false,
//       });
//     },
//     errorHandler: () => {
//       // dispatch({
//       //   type: "SET_MESSAGE",
//       //   payload: { text: error?.result, kind: "error" },
//       // });
//       dispatch({
//         type: 'SET_ORDER_LOADING',
//         payload: false,
//       });
//     },
//   });
// };

export const getOrdersByStore = (storeId = '') => (dispatch) => {
  dispatch({
    type: 'SET_ORDER_LOADING',
    payload: true,
  });
  return apiGet({
    url: `orders/merchant/${storeId}`,
    successHandler: (data) => {
      dispatch({
        type: 'SET_STORE_ORDERS',
        payload: data?.result,
        storeId,
      });
      dispatch({
        type: 'SET_ORDER_LOADING',
        payload: false,
      });
    },
    errorHandler: (error) => {
      dispatch({
        type: 'SET_MESSAGE',
        payload: { text: error.result, kind: 'error' },
      });
      dispatch({
        type: 'SET_ORDER_LOADING',
        payload: false,
      });
      dispatch({
        type: 'SET_ORDERS_LIST',
        payload: [],
      });
      dispatch({
        type: 'SET_ORDER_LOADING',
        payload: false,
      });
    },
  });
};

export const increaseProduct = (params) => (dispatch) => {
  const data = {
    ...params,
  };
  dispatch({
    type: 'SET_PRODUCT_QUANTITY_LOADING',
    payload: {
      id_product: params.id_product,
      status: true,
    },
  });
  return apiPatch({
    url: `orders${params.id_order ? `/${params.id_order}` : '/guestIncrease'}`,
    data,
    successHandler: (successData) => {
      delete successData?.result.id_product;
      const updatedOrder = successData.result;
      dispatch({
        type: 'SET_ORDER',
        payload: updatedOrder,
      });
      dispatch({
        type: 'SET_PREORDER_STORE',
        payload: {
          storeData: {
            id_store: updatedOrder?.store?.id_store,
            Addresses_id_address: updatedOrder?.store?.Addresses_id_address,
            store_name: updatedOrder?.store?.store_name,
            store_url: updatedOrder?.store?.store_url,
            currency: updatedOrder?.store?.currency,
            logo_image: updatedOrder?.store?.logo_image,
            phone_number: updatedOrder?.store?.phone_number,
            status: updatedOrder?.store?.status,
            confirmation_token_order: updatedOrder?.store?.confirmation_token_order,
          },
        },
      });
      dispatch({
        type: 'SET_PREORDER_PRODUCTS',
        payload: updatedOrder?.store?.products,
      });
      dispatch({
        type: 'SET_PRODUCT_QUANTITY_LOADING',
        payload: {
          id_product: params.id_product,
          status: false,
        },
      });
    },
    errorHandler: (error) => {
      dispatch({
        type: 'SET_MESSAGE',
        payload: { text: error, kind: 'error' },
      });
      dispatch({
        type: 'SET_PRODUCT_QUANTITY_LOADING',
        payload: {
          id_product: params.id_product,
          status: false,
        },
      });
    },
  });
};

export const decreaseProduct = (params) => (dispatch) => {
  const data = {
    ...params,
  };
  dispatch({
    type: 'SET_PRODUCT_QUANTITY_LOADING',
    payload: {
      id_product: params.id_product,
      status: true,
    },
  });
  return apiPatch({
    url: `orders${params.id_order ? `/${params.id_order}` : '/guestDecrease'}`,
    data,
    successHandler: (successData) => {
      delete successData?.result.id_product;
      const updatedOrder = successData.result;
      dispatch({
        type: 'SET_ORDER',
        payload: updatedOrder,
      });
      dispatch({
        type: 'SET_PREORDER_STORE',
        payload: {
          storeData: {
            id_store: updatedOrder?.store?.id_store,
            Addresses_id_address: updatedOrder?.store?.Addresses_id_address,
            store_name: updatedOrder?.store?.store_name,
            store_url: updatedOrder?.store?.store_url,
            currency: updatedOrder?.store?.currency,
            logo_image: updatedOrder?.store?.logo_image,
            phone_number: updatedOrder?.store?.phone_number,
            status: updatedOrder?.store?.status,
            confirmation_token_order: updatedOrder?.store?.confirmation_token_order,
          },
        },
      });
      dispatch({
        type: 'SET_PREORDER_PRODUCTS',
        payload: updatedOrder?.store?.products,
      });
      dispatch({
        type: 'SET_PRODUCT_QUANTITY_LOADING',
        payload: {
          id_product: params.id_product,
          status: false,
        },
      });
    },
    errorHandler: (error) => {
      if (error.response.status === 404) {
        dispatch({
          type: 'SET_MESSAGE',
          payload: { text: error.response.data.result.message, kind: 'error' },
        });
        dispatch({
          type: 'SET_PRODUCT_QUANTITY_LOADING',
          payload: {
            id_product: params.id_product,
            status: false,
          },
        });
      } else {
        dispatch({
          type: 'SET_MESSAGE',
          payload: { text: error, kind: 'error' },
        });
      }
    },
  });
};

export const getShippingMethod = (addressData) => (dispatch) => {
  const data = addressData;
  dispatch({
    type: 'SET_SHIPPING_LOADING',
    payload: true,
  });
  return api({
    url: 'orders/shipping',
    data,
    successHandler: (successData) => {
      dispatch({
        type: 'SET_SHIPPING_METHODS',
        payload: successData.result,
      });
      dispatch({
        type: 'SET_SHIPPING_LOADING',
        payload: false,
      });
    },
    errorHandler: (error) => {
      if (error.response.status === 404) {
        dispatch({
          type: 'SET_MESSAGE',
          payload: { text: error.response.data.result.message, kind: 'error' },
        });
      } else {
        dispatch({
          type: 'SET_MESSAGE',
          payload: { text: error, kind: 'error' },
        });
      }
      dispatch({
        type: 'SET_SHIPPING_LOADING',
        payload: false,
      });
    },
  });
};

export const setShippingMethod = (params) => (dispatch) => {
  const data = params;
  dispatch({
    type: 'SET_SHIPPING_LOADING',
    payload: true,
  });
  return apiPatch({
    url: `orders${params.id_order ? `/${params.id_order}` : '/guestShipping'}`,
    data,
    successHandler: (successData) => {
      dispatch({
        type: 'SET_ORDER',
        payload: successData?.result,
      });
      dispatch({
        type: 'SET_PREORDER_TOTALS',
        payload: {
          shipment_cost: successData?.result?.shipment_cost,
          total_price: successData?.result?.total_price,
          totalItems: successData?.result?.totalItems,
          total_discount: successData?.result?.total_discount,
          subtotal_price: successData?.result?.subtotal_price,
          registeredUser: successData?.result?.registeredUser,
        },
      });
      dispatch({
        type: 'SET_SHIPPING_LOADING',
        payload: false,
      });
    },
    errorHandler: (error) => {
      dispatch({
        type: 'SET_SHIPPING_LOADING',
        payload: false,
      });
      if (error.response.status === 404) {
        dispatch({
          type: 'SET_MESSAGE',
          payload: { text: error.response.data.result.message, kind: 'error' },
        });
      } else {
        dispatch({
          type: 'SET_MESSAGE',
          payload: { text: error, kind: 'error' },
        });
      }
    },
  });
};

export const updateShippingAddress = (params) => (dispatch) => {
  const data = {
    id_address: params.id_address,
    action: 'address',
  };
  return apiPatch({
    url: `orders/${params.id_order}`,
    data,
    successHandler: (successData) => {
      dispatch({
        type: 'SET_ORDER',
        payload: successData?.result,
      });
      dispatch({
        type: 'SET_MESSAGE',
        payload: { text: 'Order updated', kind: 'success' },
      });
    },
    errorHandler: (error) => {
      dispatch({
        type: 'SET_MESSAGE',
        payload: { text: error, kind: 'error' },
      });
    },
  });
};

export const deleteProduct = (params, navigate, localization) => (dispatch) => {
  const data = {
    id_product: params.id_product,
    action: 'delete',
  };
  dispatch({
    type: 'SET_PRODUCT_DELETE_LOADING',
    payload: {
      id_product: params.id_product,
      status: true,
    },
  });
  return apiPatch({
    url: `orders/${params.id_order}`,
    data,
    successHandler: (successData) => {
      dispatch({
        type: 'SET_ORDER',
        payload: successData?.result,
      });
      dispatch({
        type: 'SET_MESSAGE',
        payload: { text: localization.msg_success, kind: 'success' },
      });
    },
    errorHandler: (error) => {
      if (error.response.status === 404) {
        dispatch({
          type: 'SET_MESSAGE',
          payload: { text: error.response.data.result.message, kind: 'error' },
        });
        dispatch({
          type: 'CLEAR_ORDER',
        });
        navigate('/');
      } else {
        dispatch({
          type: 'SET_MESSAGE',
          payload: { text: error, kind: 'error' },
        });
      }
    },
  });
};

export const addCoupon = (params, localization) => (dispatch) => {
  const data = params;
  dispatch({
    type: 'SET_DISCOUNT_LOADING',
    payload: true,
  });
  return apiPatch({
    url: `orders${params.id_order ? `/${params.id_order}` : '/guestAddCoupon'}`,
    data,
    successHandler: (successData) => {
      if (successData?.result?.coupons.length > 0) {
        dispatch({
          type: 'SET_ORDER',
          payload: successData?.result,
        });
        dispatch({
          type: 'SET_PREORDER_TOTALS',
          payload: {
            shipment_cost: successData?.result?.shipment_cost,
            total_price: successData?.result?.total_price,
            totalItems: successData?.result?.totalItems,
            total_discount: successData?.result?.total_discount,
            subtotal_price: successData?.result?.subtotal_price,
            registeredUser: successData?.result?.registeredUser,
          },
        });
        dispatch({
          type: 'SET_PREORDER_COUPONS',
          payload: successData?.result?.coupons,
        });
        dispatch({
          type: 'SET_MESSAGE',
          payload: { text: localization.msg_success, kind: 'success' },
        });
        dispatch({
          type: 'SET_DISCOUNT_LOADING',
          payload: false,
        });
      } else {
        dispatch({
          type: 'SET_DISCOUNT_LOADING',
          payload: false,
        });
        dispatch({
          type: 'SET_MESSAGE',
          payload: { text: localization.msg_error, kind: 'error' },
        });
      }
    },
    errorHandler: (error) => {
      dispatch({
        type: 'SET_DISCOUNT_LOADING',
        payload: false,
      });
      if (error.response.status === 404) {
        dispatch({
          type: 'SET_MESSAGE',
          payload: { text: error.response.data.result.message, kind: 'error' },
        });
        dispatch({
          type: 'CLEAR_ORDER',
        });
      } else {
        dispatch({
          type: 'SET_MESSAGE',
          payload: { text: error.response.data.result.message, kind: 'error' },
        });
      }
    },
  });
};

export const removeCoupon = (params, localization) => (dispatch) => {
  const data = params;
  dispatch({
    type: 'SET_DISCOUNT_LOADING',
    payload: true,
  });
  return apiPatch({
    url: `orders${params.id_order ? `/${params.id_order}` : '/guestRemoveCoupon'}`,
    data,
    successHandler: (successData) => {
      dispatch({
        type: 'SET_ORDER',
        payload: successData?.result,
      });
      dispatch({
        type: 'SET_PREORDER_TOTALS',
        payload: {
          shipment_cost: successData?.result?.shipment_cost,
          total_price: successData?.result?.total_price,
          totalItems: successData?.result?.totalItems,
          total_discount: successData?.result?.total_discount,
          subtotal_price: successData?.result?.subtotal_price,
          registeredUser: successData?.result?.registeredUser,
        },
      });
      dispatch({
        type: 'SET_PREORDER_COUPONS',
        payload: successData?.result?.coupons,
      });
      dispatch({
        type: 'SET_MESSAGE',
        payload: { text: localization.msg_success, kind: 'success' },
      });
      dispatch({
        type: 'SET_DISCOUNT_LOADING',
        payload: false,
      });
    },
    errorHandler: (error) => {
      dispatch({
        type: 'SET_DISCOUNT_LOADING',
        payload: false,
      });
      if (error.response.status === 404) {
        dispatch({
          type: 'SET_MESSAGE',
          payload: { text: error.response.data.result.message, kind: 'error' },
        });
        dispatch({
          type: 'CLEAR_ORDER',
        });
      } else {
        dispatch({
          type: 'SET_MESSAGE',
          payload: { text: error, kind: 'error' },
        });
      }
    },
  });
};
