import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { ProductImageMobile, ProductStackMobile, SummaryCard } from './summary.styled';
import { fCurrency } from '../../../utils/formatNumber';
import SummaryModal from '../../Modals/SummaryModal';

function SummaryCardMobile({ paymentCheckout }) {
  const currentStep = useSelector((state) => state.checkoutStep);
  const order = useSelector((state) => state.orders);
  const product = order.store?.products[0];
  const { t } = useTranslation(['guest-checkout']);
  const [summaryModal, setSummaryModal] = useState(false);

  return (
    <SummaryCard active={currentStep < 4 ? 1 : 0}>
      <SummaryModal
        open={summaryModal}
        handleModal={setSummaryModal}
        paymentCheckout={paymentCheckout}
      />
      <ProductImageMobile
        src={product?.images.find((image) => image.is_default)?.image_url
          || 'https://wava-products.s3.amazonaws.com/store_logos/common-cart.webp'}
        alt="product"
      />
      <ProductStackMobile>
        <Typography variant="body2">
          {product?.name}
        </Typography>
        <Stack direction="row" justifyContent="space-between">
          <Typography
            variant="body2"
            sx={{
              fontWeight: 'bold',
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            onClick={() => setSummaryModal(true)}
          >
            {t('show-summary')}
          </Typography>
          <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
            {fCurrency(order.total_price)}
          </Typography>
        </Stack>
      </ProductStackMobile>
    </SummaryCard>
  );
}

export default SummaryCardMobile;
