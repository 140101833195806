import React, { useEffect, useState } from 'react';
import { FilloutStandardEmbed } from '@fillout/react';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { userData } from '../../../redux/actions/userActions';
import { setNewIntegration } from '../../../redux/actions/storesActions';
import useDraftFinder from '../../../hooks/useDraftFinder';

function FilloutForm() {
  const env = process.env.REACT_APP_ENVIRONMENT;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const stores = useSelector((state) => state.merchant.stores);
  const draftStore = useDraftFinder();
  const user = useSelector((state) => state.user);
  const integrationsBody = useSelector((state) => state.merchant.integrationsBody);
  const locationPath = useSelector((state) => state.location.path);
  const onboardCode = useSelector((state) => state.location.onboardCode);
  const fromPlatform = useSelector((state) => state.platform);
  const [initialTimer, setInitialTimer] = useState(60);
  const [secondTimer, setSecondTimer] = useState(15);

  const getFilloutId = () => {
    // base
    if (!onboardCode && !fromPlatform) {
      if (env === 'production') {
        return 'tj8LhBHDtaus';
      }
      return 'j7iePz5F6sus';
    }

    // tn
    if (onboardCode && fromPlatform !== 'woocommerce') {
      if (env === 'production') {
        // change to tn prod id when possible
        return 'tj8LhBHDtaus';
      }
      return '2E8tUFJ8ELus';
    }

    // wc
    if (fromPlatform === 'woocommerce' && !onboardCode) {
      if (env === 'production') {
        // change to wc prod id when possible
        return 'tj8LhBHDtaus';
      }
      // change to wc dev id when possible
      return 'j7iePz5F6sus';
    }
  };

  useEffect(() => {
    if (draftStore) {
      dispatch({
        type: 'SET_DRAFT_STORE',
        payload: draftStore,
      });
    }
    if (locationPath === '/onboard') {
      dispatch({ type: 'RESET_LOCATION' });
    }
  }, []);

  useEffect(() => {
    const intId = setInterval(() => {
      if (draftStore) {
        if (initialTimer > 0) {
          setInitialTimer(initialTimer - 1);
        }
        if (initialTimer === 0 && secondTimer === 15) {
          dispatch(userData());
        }
        if (initialTimer === 0 && secondTimer > 0) {
          setSecondTimer(secondTimer - 1);
        }
        if (secondTimer === 0) {
          setSecondTimer(15);
        }
      } else if (!fromPlatform) {
        navigate('/success');
      }
    }, 1000);

    if (!draftStore && fromPlatform) {
      dispatch(setNewIntegration(
        stores[stores.length - 1]?.id_store,
        integrationsBody,
        navigate,
      ));
    }

    return () => {
      clearInterval(intId);
    };
  }, [initialTimer, secondTimer]);

  return (
    <Grid width="100%" minHeight="100%">
      <FilloutStandardEmbed
        filloutId={getFilloutId()}
        parameters={{
          id_store: draftStore?.id_store,
          merchant: draftStore?.store_name,
          phone: draftStore?.phone_number,
          website: draftStore?.store_url,
          rut: draftStore?.store_nit,
          email: user?.email,
          platform: fromPlatform,
        }}
      />
    </Grid>
  );
}

export default FilloutForm;
