import {
  Grid, Stack, Typography, styled,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StoreLogo } from '../stepscheckout.styled';
import aboutLogo from '../../../assets/about_logo.svg';
import aboutLightning from '../../../assets/about_lightning.svg';
import aboutShield from '../../../assets/about_shield.svg';

const AboutContainer = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'stretch',
  width: '100%',
  backgroundColor: '#f6f7f6',
  color: '#191A1D',
  gap: '1rem',
  padding: '2.5rem 2rem',
}));

const AboutData = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  width: '100%',
  gap: '0.5rem',
}));

const AboutStack = styled(Stack)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  width: '100%',
  gap: '0.5rem',
}));

function AboutWava() {
  const { t } = useTranslation(['footer']);
  return (
    <AboutContainer>
      <StoreLogo src={aboutLogo} alt="Wava logo" />
      <Typography
        variant="h5"
        sx={{
          lineHeight: '1.5rem',
          width: '80%',
          marginTop: '0.5rem',
        }}
      >
        {t('footer-about-title')}
      </Typography>
      <Typography variant="body2" sx={{ lineHeight: '1.5rem' }}>
        {t('footer-about-subtitle')}
      </Typography>
      <AboutData>
        <img src={aboutLightning} alt="" style={{ marginTop: '6px' }} />
        <AboutStack>
          <Typography variant="h6">
            {t('footer-about-userdata-title')}
          </Typography>
          <Typography variant="body2">
            {t('footer-about-userdata')}
          </Typography>
        </AboutStack>
      </AboutData>
      <AboutData>
        <img src={aboutShield} alt="" style={{ marginTop: '6px' }} />
        <AboutStack>
          <Typography variant="h6">
            {t('footer-about-secure-title')}
          </Typography>
          <Typography variant="body2">
            {t('footer-about-secure')}
          </Typography>
        </AboutStack>
      </AboutData>
    </AboutContainer>
  );
}

export default AboutWava;
