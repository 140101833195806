import {
  Button,
  Grid,
  Skeleton,
  Stack,
  styled,
} from '@mui/material';

export const LastStepContainer = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%',
  paddingTop: '2rem',
  gap: '2rem',
  '@media (max-width: 780px)': {
    paddingTop: '1rem',
  },
}));

export const LastStepStack = styled(Stack)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%',
  gap: '1rem',
}));

export const LastStepWrapper = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  borderRadius: '8px',
  border: '1px solid rgba(145, 158, 171, 0.32)',
}));

export const UserInfoSum = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  padding: '1rem',
  gap: '1rem',
}));

export const UserAddressSum = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  padding: '1.5rem 1rem',
  '@media (max-width: 440px)': {
    padding: '1rem',
  },
}));

export const UserDataSkeleton = styled(Skeleton)(() => ({
  display: 'flex',
  width: '100%',
  height: '2.25rem',
}));

export const EditStepsButton = styled(Button)(() => ({
  display: 'flex',
}));

export const UserPaymentSum = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  padding: '1rem',
}));

export const SaveDataContainer = styled(Grid)(({ isauth }) => ({
  display: isauth ? 'none' : 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%',
  gap: '1rem',
}));
