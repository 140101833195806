import {
  Grid, Tooltip, styled, useMediaQuery,
} from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import shopbag from '../../../assets/shopbag_footer.svg';
import wlogo from '../../../assets/checkout_footer_logo.png';
import useTrack from '../../../hooks/useTrack';
import AboutWava from './AboutWava';

const FooterContainer = styled(Grid)(() => ({
  position: 'fixed',
  bottom: 0,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around',
  alignItems: 'center',
  width: '100%',
  backgroundColor: '#F0F2F1',
  height: '2.5rem',
  minHeight: '2.5rem',
  fontSize: '12px',
  color: '#484848',
  gap: '1rem',
}));

const FooterSection = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
}));

const FooterItem = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
}));

const FooterLink = styled('a')(() => ({
  color: '#484848',
  '&:visited': {
    color: '#484848',
  },
}));

const FooterText = styled('p')(() => ({
  color: '#484848',
  textDecoration: 'underline',
  cursor: 'default',
}));

function Footer() {
  const { t } = useTranslation(['footer']);
  const order = useSelector((state) => state.orders);
  const checkoutStep = useSelector((state) => state.checkoutStep);
  const isMobile = useMediaQuery('(max-width:780px)');
  const [wavaTooltip, setWavaTooltip] = useState(false);

  const handleTooltip = (showTooltip) => {
    setWavaTooltip(showTooltip);
    if (showTooltip) {
      useTrack('about_wava', {
        pantalla: `Paso ${checkoutStep}`,
        merchant: order?.store?.store_name,
      });
    }
  };

  return (
    <FooterContainer sx={isMobile ? { display: 'none' } : { display: 'flex' }}>
      <FooterSection sx={{ paddingLeft: '4rem' }}>
        <FooterItem gap={1}>
          <img src={shopbag} alt="Shop Icon" />
          <p>{t('secure-payment')}</p>
        </FooterItem>
        <FooterLink
          onClick={() => useTrack('politicas_privacidad', {
            pantalla: `Paso ${checkoutStep}`,
            merchant: order?.store?.store_name,
          })}
          target="_blank"
          href="https://wava.co/politica-de-privacidad"
        >
          {t('footer-privacy')}
        </FooterLink>
      </FooterSection>
      <FooterSection sx={{ paddingRight: '4rem' }}>
        <FooterLink
          onClick={() => useTrack('terminos_condiciones', {
            pantalla: `Paso ${checkoutStep}`,
            merchant: order?.store?.store_name,
          })}
          target="_blank"
          href="https://wava.co/terminos-y-condiciones/"
        >
          {t('footer-terms')}
        </FooterLink>
        <Tooltip
          title={(<AboutWava />)}
          placement="top-start"
          PopperProps={{ style: { zIndex: 1 } }}
          open={wavaTooltip}
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: '#f6f7f6',
                minWidth: '23rem',
                border: '0.5px solid #f4f5f5',
                boxShadow: '0px 16px 32px 0px rgba(28, 34, 40, 0.13)',
              },
            },
          }}
          onMouseEnter={() => handleTooltip(true)}
          onMouseLeave={() => handleTooltip(false)}
        >
          <FooterItem gap={1}>
            <FooterText>
              {t('footer-about')}
            </FooterText>
            <img src={wlogo} width="60px" alt="Shop Icon" />
          </FooterItem>
        </Tooltip>
      </FooterSection>
    </FooterContainer>
  );
}

export default Footer;
