/* eslint-disable arrow-body-style */
/* eslint-disable no-console */
import mixpanel from 'mixpanel-browser';
import getCookie from '../utils/getCookie';

const env = process.env.REACT_APP_ENVIRONMENT;
const testing = true;

export default function useTrack(eventTitle, data) {
  const distinctId = getCookie('mixid');

  if (distinctId) {
    mixpanel.register({ distinct_id: `${distinctId}` });
  }

  if (env === 'development') {
    return testing
      ? (data
        ? (distinctId
          ? console.log(eventTitle, { ...data, 'Distinct ID': distinctId })
          : console.log(eventTitle, data))
        : console.log(eventTitle))
      : (data ? (distinctId
        ? mixpanel.track(eventTitle, { ...data, 'Distinct ID': distinctId })
        : mixpanel.track(eventTitle, data))
        : mixpanel.track(eventTitle));
  }
  if (env === 'production') {
    return data ? (distinctId
      ? mixpanel.track(eventTitle, { ...data, 'Distinct ID': distinctId })
      : mixpanel.track(eventTitle, data))
      : mixpanel.track(eventTitle);
  }
}
