import {
  Accordion, Button, Grid, Stack, styled,
} from '@mui/material';
import MuiAccordionSummary from '@mui/material/AccordionSummary';

export const UserPaymentContainer = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'stretch',
  width: '100%',
  paddingTop: '1rem',
}));

export const AccordionsContainer = styled(Grid)(({ modaledit }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  marginTop: modaledit ? 'none' : '1rem',
  marginBottom: modaledit ? '1rem' : 'none',
  borderRadius: '8px',
  border: '1px solid rgba(145, 158, 171, 0.32)',
}));

export const AccordionStyled = styled(Accordion)(() => ({
  width: '100%',
  minHeight: 0,
  '&.Mui-expanded': {
    minHeight: 0,
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    margin: '0',
  },
  p: 0,
  margin: 0,
}));

export const AccordionSummaryStyled = styled(MuiAccordionSummary)(() => ({
  margin: 0,
  padding: 0,
  minHeight: 0,
  '& .MuiAccordionSummary-content': {
    margin: '0',
  },
}));

export const AccordionSummaryContainer = styled(Grid)(() => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '1rem',
  borderRadius: 1,
}));

export const BankDataStack = styled(Stack)(({ active }) => ({
  display: active ? 'flex' : 'none',
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: '0.3rem',
}));

export const CardIconsContainer = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: '1rem',
  width: 'fit-content',
  flexWrap: 'nowrap',
}));

export const CreditCardContainer = styled(Stack)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '1rem',
  marginLeft: '0.5rem',
  marginRight: '0.5rem',
}));

export const CreditCardStack = styled(Stack)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: '0.5rem',
}));

export const AddCardContainer = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  alignSelf: 'flex-start',
  gap: '0.5rem',
  marginTop: '1rem',
  cursor: 'pointer',
}));

export const PaymentContinueButton = styled(Button)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  textTransform: 'none',
  gap: '0.5rem',
  width: '100%',
  height: '3rem',
  borderRadius: '100px',
}));
