/* eslint-disable camelcase */
import React from 'react';
import { Chip } from '@mui/material';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import { ChipContainer, SummaryCouponsContainer, SummaryCouponsStack } from '../summary.styled';
import { TextInputStyled } from '../../stepscheckout.styled';

function SummaryCoupons({
  currentStep, setCoupon, applyDiscount, deleteDiscount, paymentCheckout,
}) {
  const order = useSelector((state) => state.orders);
  const { t } = useTranslation(['guest-checkout']);

  return (
    <SummaryCouponsContainer sx={paymentCheckout ? { display: 'none' } : {}} active={currentStep !== 5 ? 1 : 0}>
      <SummaryCouponsStack>
        <TextInputStyled
          disabled={order.loadingDiscount || order.confirmation?.loading || currentStep === 6}
          fullWidth
          placeholder={t('discount-input')}
          onChange={(e) => setCoupon(e.target.value)}
        />
        <LoadingButton
          variant="contained"
          size="large"
          sx={{ borderRadius: '6.25rem', boxShadow: 'none' }}
          disabled={order.confirmation?.loading || currentStep === 6}
          onClick={() => applyDiscount()}
          loading={order.loadingDiscount}
        >
          {t('apply')}
        </LoadingButton>
      </SummaryCouponsStack>
      {order.coupons.length > 0
        && order.coupons.map((coupon) => (
          <ChipContainer key={coupon.code}>
            <Chip
              disabled={currentStep === 6}
              label={coupon.code}
              variant="outlined"
              onDelete={() => deleteDiscount(coupon.code)}
            />
          </ChipContainer>
        ))}
    </SummaryCouponsContainer>
  );
}

export default SummaryCoupons;
