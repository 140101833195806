import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CheckCircleIcon from '../../assets/CheckCircleIcon';
import { SuccessContainer } from './Login.styled';

function OnboardSuccess() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAuthenticated } = useSelector((state) => state.user);
  const stores = useSelector((state) => state.merchant.stores);
  const selectedStore = useSelector((state) => state.merchant.selectedStore);
  const previousDraftStore = useSelector((state) => state.merchant.draftStore);
  const globalStore = selectedStore
    || stores.find((s) => s.id_store === previousDraftStore?.id_store);
  const { integrationRedirect } = useSelector((state) => state.merchant);
  const platform = useSelector((state) => state.platform);

  const handleRedirect = () => {
    if (integrationRedirect) {
      window.parent.location.href = integrationRedirect;
    }
  };

  const newStoreLogin = () => {
    dispatch({
      type: 'SET_SELECTED_STORE',
      payload: globalStore,
    });
    navigate('/dashboard');
  };

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login');
    }
  }, [isAuthenticated]);

  return (
    <SuccessContainer>
      <Stack direction="column" justifyContent="flex-start" alignItems="center" gap={2}>
        <CheckCircleIcon sx={{ width: '50px' }} />
        <Typography variant="h3">
          {platform ? '¡Tu integración con Wava ha sido exitosa!' : '¡Tu tienda ha sido creada con éxito!'}
        </Typography>
        <Typography variant="body1">
          Ya puedes comenzar a utilizar nuestros servicios con tu nueva tienda
          {' '}
          <b>
            {`${globalStore?.store_name}`}
          </b>
          {' '}
          y descubrir todo lo que tenemos para ti.
        </Typography>
      </Stack>
      <Stack direction="row" justifyContent="space-between" width="100%" mt={4}>
        <Button
          variant="outlined"
          onClick={() => handleRedirect()}
          sx={{
            borderRadius: '6rem',
            textTransform: 'none',
            visibility: platform && integrationRedirect ? 'visible' : 'hidden',
          }}
        >
          Regresar
        </Button>
        <Button
          variant="contained"
          onClick={() => newStoreLogin()}
          sx={{ borderRadius: '6rem', textTransform: 'none' }}
        >
          Ingresar a Wava
        </Button>
      </Stack>
    </SuccessContainer>
  );
}

export default OnboardSuccess;
