/* eslint-disable no-unused-expressions */

const apiUrl = process.env.REACT_APP_MS_PAYMENTS ? process.env.REACT_APP_MS_PAYMENTS : 'https://dev.payments.wava.co/';
const apiSecret = process.env.REACT_APP_MS_PAYMENTS_SECRET ? process.env.REACT_APP_MS_PAYMENTS_SECRET : 'ABCDEFGABCDEFG';

function setHeaders(headers, token) {
  if (token) {
    return {
      ...headers,
      'x-auth-token': `${token}`,
    };
  }
  return {
    ...headers,
    'x-auth-token': `${apiSecret}`,
  };
}

export const apiPost = (props, token = false) => {
  const {
    url,
    data = {},
    successHandler = (response) => response,
    errorHandler = (error) => {
      console.error('fetch failed', error);
    },
  } = props;
  return fetch(`${apiUrl}${url}`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: setHeaders({
      'Content-Type': 'application/json',
    }, token),
  })
    .then((response) => response.json())
    .then((decodedResponse) => {
      decodedResponse.error
        ? errorHandler(
          decodedResponse?.result?.message
            ? decodedResponse?.result?.message
            : decodedResponse?.result,
        )
        : successHandler(decodedResponse);
    })
    .catch((err) => {
      console.log('err', err);
      errorHandler(err);
    });
};

export const apiGet = (props, token) => {
  const {
    url,
    successHandler = (response) => response,
    errorHandler = (error) => {
      console.error('fetch failed', error);
    },
  } = props;
  return fetch(`${apiUrl}${url}`, {
    method: 'GET',
    headers: setHeaders({
      'Content-Type': 'application/json',
    }, token),
  })
    .then((response) => response.json())
    .then((decodedResponse) => {
      decodedResponse.error
        ? errorHandler(
          decodedResponse?.result?.message
            ? decodedResponse?.result?.message
            : decodedResponse?.result,
        )
        : successHandler(decodedResponse);
    })
    .catch((err) => {
      console.log('err', err);
      errorHandler(err);
    });
};

export const apiPatch = (props, token) => {
  const {
    url,
    data = {},
    successHandler = (response) => response,
    errorHandler = (error) => {
      console.error('fetch failed', error);
    },
  } = props;
  return fetch(`${apiUrl}${url}`, {
    method: 'PATCH',
    body: JSON.stringify(data),
    headers: setHeaders({
      'Content-Type': 'application/json',
    }, token),
  })
    .then((response) => response.json())
    .then((decodedResponse) => {
      decodedResponse.error
        ? errorHandler(
          decodedResponse?.result?.message
            ? decodedResponse?.result?.message
            : decodedResponse?.result,
        )
        : successHandler(decodedResponse);
    })
    .catch((err) => {
      console.log('err', err);
      errorHandler(err);
    });
};

export const apiPut = (props) => {
  const {
    url,
    data = {},
    successHandler = (response) => response,
    errorHandler = (error) => {
      console.error('fetch failed', error);
    },
  } = props;
  return fetch(`${apiUrl}${url}`, {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: setHeaders({
      'Content-Type': 'application/json',
    }),
  })
    .then((response) => response.json())
    .then((decodedResponse) => {
      decodedResponse.error
        ? errorHandler(
          decodedResponse?.result?.message
            ? decodedResponse?.result?.message
            : decodedResponse?.result,
        )
        : successHandler(decodedResponse);
    })
    .catch((err) => {
      console.log('err', err);
      errorHandler(err);
    });
};

export const apiDelete = (props, token) => {
  const {
    url,
    successHandler = (response) => response,
    errorHandler = (error) => {
      console.error('fetch failed', error);
    },
  } = props;
  return fetch(`${apiUrl}${url}`, {
    method: 'DELETE',
    headers: setHeaders({
      'Content-Type': 'application/json',
    }, token),
  })
    .then((response) => response.json())
    .then((decodedResponse) => {
      decodedResponse.error
        ? errorHandler(
          decodedResponse?.result?.message
            ? decodedResponse?.result?.message
            : decodedResponse?.result,
        )
        : successHandler(decodedResponse);
    })
    .catch((err) => {
      console.log('err', err);
      errorHandler(err);
    });
};
