/* eslint-disable prefer-destructuring */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Grid, Divider, useMediaQuery,
} from '@mui/material';
import {
  DataContainer,
  StepsSection,
  StoreLogoContainer,
  StoreLogo,
  CheckoutContainer,
} from './stepscheckout.styled';
import LoadingScreen from '../LoadingScreen';
import CodeStep from './CodeStep';
import Summary from './Summary/Summary';
import NequiConfirmation from '../Nequi/NequiConfirmation';
import { orders } from '../../redux/actions/ordersAction';
import OrderCompleteStep from './OrderCompleteStep/OrderCompleteStep';
import EmptyContent from '../EmptyContent';
import Footer from './Footer/Footer';
import UserPayment from './UserPayment/UserPayment';
import { SummarySection } from './Summary/summary.styled';
import SummaryCardMobile from './Summary/SummaryCardMobile';
import useTrack from '../../hooks/useTrack';
import wavaLogo from '../../assets/dashboard/logo_wava.svg';
import PaymentForm from './UserPayment/PaymentForm';

function PaymentHash() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { hash } = useParams();
  const isMobile = useMediaQuery('(max-width:780px)');
  const { t } = useTranslation(['purchase']);

  const orderData = useSelector((state) => state.orders);
  const user = useSelector((state) => state.user);
  const [userDataSaved, setUserDataSaved] = useState(false);
  const [userDataFromParams, setUserDataFromParams] = useState({
    id_store: '',
    email: '',
    phone_number: '',
    first_name: '',
    last_name: '',
    id_national_document_type: '',
    national_id_number: '',
  });

  const [creditCard, setCreditCard] = useState(
    user?.isAuthenticated
      ? user?.payment_methods[0]?.credit_cards?.find((cc) => cc.is_default === 1)
      : false,
  );

  const ccSessionData = JSON.parse(sessionStorage.getItem('cc'));

  const selectedStep = useSelector((state) => state.checkoutStep);

  const paymentMethods = orderData?.store?.allowed_payment_methods;
  const cardMethod = paymentMethods?.find((method) => (method?.gateway_type === 'card'));

  const submitCreditCard = (values) => {
    useTrack('pago_tarjeta', {
      pantalla: 'Paso 3 - Forma de pago',
      merchant: orderData?.store?.store_name,
    });
    if (values) {
      dispatch({
        type: 'SET_USER_DATA',
        payload: { payment_methods: [{ credit_cards: [values] }] },
      });
      dispatch({
        type: 'SET_PAYMENT_METHOD',
        payload: {
          ...values,
          ...cardMethod,
          id_credit_card_external: values.id_external,
        },
      });
      dispatch({
        type: 'SET_PREORDER_PAYMENT',
        payload: {
          ...values,
          ...cardMethod,
          id_credit_card_external: values.id_external,
        },
      });
    }
  };

  useEffect(() => {
    setCreditCard(
      user?.isAuthenticated
        ? user?.payment_methods[0]?.credit_cards?.find((cc) => cc.is_default === 1)
        : user?.payment_methods[0]?.credit_cards[0],
    );
    if (orderData?.shopper) {
      setUserDataFromParams({ ...orderData?.shopper });
    }
  }, [user, orderData]);

  useEffect(() => {
    dispatch(orders({ order_hash: hash }, { msg_success: t('msg_success') }, navigate));
    dispatch({
      type: 'SET_CHECKOUT_STEP',
      payload: 3,
    });
    if (!orderData?.store && !user.isAuthenticated) {
      localStorage.removeItem('state');
      sessionStorage.removeItem('state');
      dispatch({
        type: 'SET_CHECKOUT_STEP',
        payload: 3,
      });
      dispatch({
        type: 'RESET_USER',
      });
    }
    if (ccSessionData) {
      submitCreditCard(ccSessionData);
    }
  }, [user.isAuthenticated]);

  return (
    orderData?.loadingOrders
      ? <LoadingScreen />
      : (
        <CheckoutContainer>
          {orderData?.store
            ? (
              <Grid
                container
                maxWidth="lg"
                sx={{
                  display: 'flex',
                  flexDirection: isMobile ? 'column' : 'row',
                  paddingLeft: isMobile ? '16px' : '2rem',
                  paddingRight: isMobile ? '16px' : '2rem',
                  gap: isMobile ? '1rem' : '3rem',
                  height: isMobile ? 'auto' : '100%',
                  minHeight: isMobile ? 'auto' : '820px',
                }}
              >
                <StepsSection mt={isMobile ? 2 : 4} mb={isMobile ? 0 : 8}>
                  <StoreLogoContainer mobile={isMobile ? 1 : 0}>
                    {orderData?.store?.logo_image
                      ? <StoreLogo src={orderData?.store?.logo_image} alt="Store Logo" />
                      : <StoreLogo src={wavaLogo} alt="Wava logo" />}
                  </StoreLogoContainer>
                  {isMobile && <SummaryCardMobile paymentCheckout />}
                  {selectedStep === 0 && <CodeStep />}

                  <DataContainer sx={{ gap: '1rem' }}>
                    {selectedStep === 3 && (
                      <PaymentForm
                        paramsData={userDataFromParams}
                        emptyCart={!orderData?.store}
                        userDataSaved={userDataSaved}
                        setUserDataSaved={setUserDataSaved}
                      />
                    )}
                    {selectedStep === 3
                      && (
                        <UserPayment
                          stepTitle={t('third-step-title')}
                          defaultPayment={creditCard || ccSessionData}
                          paymentMethods={orderData?.store?.allowed_payment_methods}
                          submitCreditCard={submitCreditCard}
                          prevStep={!orderData.isDigital ? t('second-step') : null}
                          formIncomplete={!userDataSaved}
                          sessionData={Boolean(ccSessionData)}
                        />
                      )}
                    {selectedStep === 5 && <OrderCompleteStep />}
                    {selectedStep === 6 && <NequiConfirmation />}
                  </DataContainer>
                </StepsSection>

                <Grid sx={isMobile ? { display: 'none' } : { display: 'flex' }}>
                  <Divider orientation="vertical" />
                </Grid>

                <SummarySection>
                  <Summary currentStep={selectedStep} paymentCheckout />
                </SummarySection>
              </Grid>
            )
            : (
              <EmptyContent />
            )}
          <Footer />
        </CheckoutContainer>
      )
  );
}

export default PaymentHash;
