/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  CircularProgress, Grid, IconButton, Stack,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { addPayment, getTokenVGS } from '../../redux/actions/paymentsAction';

export default function VGSForm({
  submit, cta_label, showform, hideform,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation(['credit-card']);
  const user = useSelector((state) => state.user);
  const formLoading = useSelector((state) => state.payments.loading_vgs_token);

  useEffect(() => {
    dispatch(getTokenVGS(user, (token) => {
      const checkout = new window.VGSCheckout.Checkout({
        tenantId: process.env.REACT_APP_MS_PAYMENTS_VAULT_ID ? process.env.REACT_APP_MS_PAYMENTS_VAULT_ID : 'tntnyshxlet',
        environment: process.env.REACT_APP_MS_PAYMENTS_ENVIRONMENT ? process.env.REACT_APP_MS_PAYMENTS_ENVIRONMENT : 'sandbox',
        accessToken: token,
      });

      checkout.mount('#vgs-checkout-form', {
        theme: {
          colors: {
            ctaButtonBg: '#191A1D',
          },
          fonts: {
            base: 'Inter',
          },
          shadows: {
            inputBorder: '0 0 0 1px #D9D9D9',
            inputBorderActive: '0 0 0 1px #191A1D',
          },
        },
        labels: {
          submit: {
            ctaLabel: cta_label,
            processingLabel: t('adding-card'),
            success: {
              label: t('card-added-success'),
              message: t('card-added-success-message'),
            },
          },
          cardHolder: {
            placeholder: t('card-name-placeholder'),
          },
          cardNumber: {
            label: t('card-number-label'),
          },
          cardCvc: {
            label: t('card-cvv-label'),
          },
          cardExp: {
            label: t('card-exp-date-label'),
          },
          errorMessages: {
            empty: t('error-empty-field'),
            cardNumberInvalid: t('error-invalid-card-number'),
            cardNumberNotAllowed: t('error-notallowed-card-number'),
            cardSecurityCodeInvalid: t('error-invalid-card-cvv'),
            cardExpirationDateInvalid: t('error-invalid-card-date'),
          },
        },
        style: {
          form: {
            padding: '0.1rem',
          },
          fieldset: {
            '&>div>div': {
              paddingBottom: '1rem',
              minHeight: 0,
            },
            '&>div>div>label>div>span': {
              top: '10px',
            },
            '&>div>div>div': {
              paddingBottom: '0.5rem',
              minHeight: 0,
            },
            '&>div>div>div>label>div>span': {
              top: '10px',
            },
          },
          legend: {
            display: 'none',
          },
          baseFormControl: {
            '&>span': {
              display: 'none',
            },
            borderRadius: '10px',
            marginTop: '0px',
          },
          submitButton: {
            marginTop: 0,
            borderRadius: '100px',
            fontWeight: '700',
            fontSize: '0.875rem',
            padding: '6px 16px',
            textTransform: 'none',
            lineHeight: '1.7',
          },
        },
      });

      checkout.on('SubmitSuccess', ({ data }) => {
        const exp_month = data.data.card.exp_month.toString().slice(-2);
        const new_credit_card = {
          card_number: data.data.card.number,
          cvc: data.data.card.cvc,
          brand: data.data.card.brand,
          id_external: data.data.id,
          expiry_date: `${(exp_month.length === 1 ? '0' : '') + exp_month.toString()}/${data.data.card.exp_year.toString().slice(-2)}`,
          name_on_card: data.data.card.name,
          last_four: data.data.card.last4.toString(),
        };
        if (submit) {
          submit(new_credit_card);
          sessionStorage.setItem('cc', JSON.stringify(new_credit_card));
          dispatch({
            type: 'SET_MESSAGE',
            payload: { text: 'Tarjeta añadida con éxito', kind: 'success' },
          });
        } else {
          dispatch(
            addPayment(
              user,
              new_credit_card,
              { msg_success: t('card-added-success-message') },
              hideform,
            ),
          );
          dispatch({
            type: 'SET_MESSAGE',
            payload: { text: 'Tarjeta añadida con éxito', kind: 'success' },
          });
        }
        document.cookie = 'vgsToken=;expires=Thu, 01 Jan 1970 00:00:01 GMT';
        checkout.unmount();
      });
    }));
  }, []);

  return (
    <Grid item sx={formLoading ? { display: 'flex', justifyContent: 'center' } : {}}>
      {showform && !formLoading
        && (
          <Stack alignItems="flex-end">
            <IconButton onClick={() => hideform(false)}>
              <Close />
            </IconButton>
          </Stack>
        )}
      {formLoading
        ? <CircularProgress color="warning" />
        : (
          <div id="vgs-checkout-form" />
        )}
    </Grid>
  );
}
