/* eslint-disable max-len */
/* eslint-disable no-restricted-syntax */
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box, Grid, Stack, Typography, useMediaQuery,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Country, State, City } from 'country-state-city';
import Flag from 'react-world-flags';
import {
  FormStyled, SelectInputStyled, StepContinueButton, TextInputStyled,
} from '../stepscheckout.styled';
import { handleNumericRegex } from '../../../utils/regex';

function CreditCardAddress({ address }) {
  const dispatch = useDispatch();
  const orderData = useSelector((state) => state.orders);
  const { t } = useTranslation(['shipping-address-edit']);
  const isMobile = useMediaQuery('(max-width:650px)');

  const countries = Country.getAllCountries();
  const geo = countries?.find((c) => c.name === orderData?.store?.country_name)?.isoCode;
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const [countryCode, setCountryCode] = useState((address?.country_code ? address?.country_code : geo) || '');
  const [postalCode, setPostalCode] = useState(address?.postal_code || '');
  const [countryName, setCountryName] = useState(address?.country_name || '');
  const [cityName, setCityName] = useState(address?.city_name || '');
  const [stateName, setStateName] = useState(address?.state_name || '');
  const [stateCode, setStateCode] = useState(address?.state_code || '');
  const [addressField, setAddressField] = useState(address?.address_field_1 || '');
  const [addressComments, setAddressComments] = useState(address?.address_field_2 || '');
  const [values, setValues] = useState({
    postal_code: address?.postal_code || '',
    country_name: address?.country_name || '',
    country_code: (address?.country_code ? address?.country_code : geo) || '',
    city_name: address?.city_name || '',
    state_name: address?.state_name || '',
    state_code: address?.state_code || '',
    address_field_1: address?.address_field_1 || '',
    address_field_2: address?.address_field_2 || '',
  });
  const [countryCodeError, setCountryCodeError] = useState(false);
  const [addressError, setAddressError] = useState(false);
  const [stateCodeError, setStateCodeError] = useState(false);
  const [cityError, setCityError] = useState(false);

  useEffect(() => {
    if (countryCode !== '') {
      setStates(State.getStatesOfCountry(countryCode));
    } else {
      setStates([]);
    }

    if (countryCode !== '' && stateCode !== '') {
      setCities(City.getCitiesOfState(countryCode, stateCode));
    } else {
      setCities([]);
    }
    setStateName(State.getStateByCodeAndCountry(stateCode, countryCode)?.name);
    setCountryName(Country.getCountryByCode(countryCode)?.name);
  }, [countryCode, stateCode]);

  useEffect(() => {
    setValues({
      postal_code: postalCode?.trim(),
      country_name: countryName?.trim(),
      country_code: countryCode?.trim(),
      city_name: cityName?.trim(),
      state_name: stateName?.trim(),
      state_code: stateCode?.trim(),
      address_field_1: addressField?.trim(),
      address_field_2: addressComments?.trim(),
    });
  }, [
    postalCode,
    countryName,
    countryCode,
    cityName,
    stateName,
    stateCode,
    addressField,
  ]);

  const onSubmit = async (e) => {
    e.preventDefault();
    if (address?.id_address) {
      setValues({ ...values, id_address: address?.id_address });
    }
    if (JSON.stringify(values) !== JSON.stringify(address)) {
      sessionStorage.setItem('billing', JSON.stringify(values));
      dispatch({
        type: 'SET_USER_DATA',
        payload: { addresses: values },
      });
      dispatch({
        type: 'SET_PREORDER_SHOPPER_ADDRESS',
        payload: values,
      });
      dispatch({
        type: 'SET_PREORDER_ADDRESS',
        payload: values,
      });
      dispatch({
        type: 'SET_MESSAGE',
        payload: { text: 'Dirección añadida con éxito', kind: 'success' },
      });
    }
  };

  const inputRef = useRef();

  const handleErrors = () => {
    if (countryCode === '') {
      setCountryCodeError(true);
    }
    if (addressField === '') {
      setAddressError(true);
    }
    if (stateCode === '') {
      setStateCodeError(true);
    }
    if (cityName === '') {
      setCityError(true);
    }
  };

  useEffect(() => {
    if (countryCode !== '' && countryCodeError) {
      setCountryCodeError(false);
    }
    if (addressField !== '' && addressError) {
      setAddressError(false);
    }
    if (stateCode !== '' && stateCodeError) {
      setStateCodeError(false);
    }
    if (cityName !== '' && cityError) {
      setCityError(false);
    }
  }, [countryCode, stateCode, addressField, cityName]);

  return (
    <FormStyled onSubmit={(e) => onSubmit(e)}>
      <Typography variant="body1" mb={2} mx={1}>
        Dirección de facturación
      </Typography>
      <Box sx={{ display: 'grid', columnGap: 2, rowGap: 3 }}>
        <Stack direction={isMobile ? 'column' : 'row'} gap={3}>
          <SelectInputStyled
            id="country_code"
            label={t('country_name_label')}
            placeholder={t('country_name_ph')}
            select
            required
            value={countryCode}
            error={countryCodeError}
            helperText={countryCodeError ? t('country_name_required') : undefined}
            SelectProps={{ native: true }}
            InputLabelProps={{ shrink: true, required: false }}
            onChange={(e) => setCountryCode(e.target.value)}
            InputProps={countryCode ? {
              startAdornment:
                (<Flag
                  code={countryCode}
                  style={{ marginRight: '0.5rem' }}
                  width="30"
                  fallback=""
                />),
            } : null}
          >
            <option value="" style={{ display: 'none' }}>
              {t('country_name_ph')}
            </option>
            {countries.map((country) => (
              <option
                key={country.isoCode}
                value={country.isoCode}
              >
                {country.name}
              </option>
            ))}
          </SelectInputStyled>
          <TextInputStyled
            id="postal_code"
            required
            label={t('postal_code_label')}
            placeholder={t('postal_code_ph')}
            sx={{ display: 'flex', flex: 1 }}
            InputLabelProps={{ shrink: true }}
            value={postalCode}
            onChange={(e) => handleNumericRegex(e.target.value, setPostalCode)}
          />
        </Stack>
        <Grid
          container
          justifyContent="space-between"
          direction={isMobile ? 'column' : 'row'}
          gap={3}
        >
          <SelectInputStyled
            id="state_code"
            label={t('state_name_label')}
            placeholder={t('state_name_ph')}
            select
            required
            value={stateCode}
            error={stateCodeError}
            helperText={stateCodeError ? t('state_name_required') : undefined}
            SelectProps={{ native: true }}
            InputLabelProps={{ shrink: true, required: false }}
            sx={{ display: 'flex', flex: 1, width: '100%' }}
            onChange={(e) => setStateCode(e.target.value)}
          >
            <option value="" style={{ display: 'none', color: '#A0A0A0' }}>
              {t('state_name_ph')}
            </option>
            {states.map((state) => (
              <option
                key={state.isoCode}
                value={state.isoCode}
              >
                {state.name}
              </option>
            ))}
          </SelectInputStyled>
          {
            cities.length === 0
              ? (
                <TextInputStyled
                  id="city_name"
                  label={t('city_name_label')}
                  placeholder={t('city_name_label')}
                  required
                  value={cityName}
                  error={cityError}
                  helperText={cityError ? t('city_name_required') : undefined}
                  sx={{ display: 'flex', flex: 1 }}
                  InputLabelProps={{ shrink: true, required: false }}
                  onChange={(e) => setCityName(e.target.value)}
                />
              )
              : (
                <SelectInputStyled
                  id="city_name"
                  label={t('city_name_label')}
                  placeholder={t('city_name_ph_select')}
                  select
                  required
                  value={cityName}
                  error={cityError}
                  helperText={cityError ? t('city_name_required') : undefined}
                  InputLabelProps={{ shrink: true, required: false }}
                  SelectProps={{ native: true }}
                  onChange={(e) => setCityName(e.target.value)}
                  sx={{ display: 'flex', flex: 1, width: '100%' }}
                >
                  <option value="" style={{ display: 'none', color: '#A0A0A0' }}>
                    {t('state_name_ph')}
                  </option>
                  {cities.map((city) => (
                    <option
                      key={city.stateCode + city.name}
                      value={city.name}
                    >
                      {city.name}
                    </option>
                  ))}
                </SelectInputStyled>
              )
          }
        </Grid>
        <TextInputStyled
          id="address_field_1"
          label={t('address_field_1_label')}
          required
          value={addressField}
          error={addressError}
          helperText={addressError ? t('address_field_1_required') : undefined}
          InputLabelProps={{ shrink: true, required: false }}
          inputProps={{
            ref: inputRef,
          }}
          onChange={(e) => setAddressField(e.target.value)}
        />
        <TextInputStyled
          label={t('address_field_2_label')}
          placeholder={t('address_field_2_ph')}
          InputLabelProps={{ shrink: true }}
          value={addressComments}
          onChange={(e) => setAddressComments(e.target.value)}
        />
      </Box>
      <Stack
        direction={isMobile ? 'column-reverse' : 'row'}
        justifyContent="space-between"
        alignItems="center"
        mt={3}
        gap={isMobile ? 2 : 4}
      >
        <StepContinueButton
          type="submit"
          variant="contained"
          disabled={
            ((orderData?.store?.products
              && Boolean(orderData?.store?.products.find((p) => p.loadingQuantity === true)))
              || orderData?.loadingShipping
            )
          }
          onClick={() => handleErrors()}
          loading={orderData.loadingShipping}
        >
          {t('cta_label')}
        </StepContinueButton>
      </Stack>
    </FormStyled>
  );
}

export default CreditCardAddress;
