import {
  Checkbox,
  Divider,
  Grid,
  Stack, Typography, useMediaQuery,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import {
  increaseProduct,
  decreaseProduct,
  deleteProduct,
  addCoupon,
  removeCoupon,
  placeOrder,
} from '../../../redux/actions/ordersAction';
import { fCurrency } from '../../../utils/formatNumber';
// import useTrack from '../../../hooks/useTrack';
import SummaryProducts from './SummaryProducts/SummaryProducts';
import SummaryCoupons from './SummaryCoupons/SummaryCoupons';
import { SummaryContainer, SummaryDataStack, SummaryWrapper } from './summary.styled';
import { SaveDataContainer } from '../LastStep/laststep.styled';
import useTrack from '../../../hooks/useTrack';
import ReturnToStore from '../ReturnToStore';

export default function Summary({
  currentStep, orderMsg, fromModal, paymentCheckout,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation(['guest-checkout']);
  const userData = useSelector((state) => state.user);
  const [couponCode, setCouponCode] = useState(false);
  const order = useSelector((state) => state.orders);
  const paymentMethod = order?.payment_method;
  const totalPrice = fCurrency(order.total_price);
  const displayShipping = order.store.id_shipment_method_external !== null ? t('shipping-pending') : '-';
  const isAuth = useSelector((state) => state.user.isAuthenticated);
  const preorderData = useSelector((state) => state.preorder);
  const saveUserData = useSelector((state) => state.preorder.save_user_data);
  const checkoutStep = useSelector((state) => state.checkoutStep);
  const isMobile = useMediaQuery('(max-width:780px)');

  const { totalItems } = order;

  const handleDeleteCart = (productId) => {
    dispatch(deleteProduct({
      id_product: productId,
      id_order: order.id_order,
    }, navigate, {
      msg_success: t('msg_success-product-removed'),
    }));
  };

  const handleEditProduct = () => {
    navigate('/review_order', { state: { fromCheckout: true } });
  };

  const handleIncreaseProduct = (productId) => {
    useTrack('cantidad_mas', {
      pantalla: `Paso ${currentStep}`,
      merchant: order?.store?.store_name,
    });
    if (userData.isAuthenticated) {
      dispatch(increaseProduct({
        id_product: productId,
        id_order: order.id_order,
        action: 'increase',
      }));
    } else {
      dispatch(increaseProduct({
        ...preorderData,
        id_order: order.id_order,
        action: 'increase',
        id_product: productId,
      }));
    }
  };

  const handleDecreaseProduct = (productId) => {
    useTrack('cantidad_menos', {
      pantalla: `Paso ${currentStep}`,
      merchant: order?.store?.store_name,
    });
    if (userData.isAuthenticated) {
      dispatch(decreaseProduct({
        id_product: productId,
        id_order: order.id_order,
        action: 'decrease',
      }));
    } else {
      dispatch(decreaseProduct({
        ...preorderData,
        id_product: productId,
        id_order: order.id_order,
        action: 'decrease',
      }));
    }
  };

  const handleDeleteDiscount = (value) => {
    if (value) {
      useTrack('cupon_borrar', {
        pantalla: `Paso ${currentStep}`,
        merchant: order?.store?.store_name,
      });
      if (isAuth) {
        dispatch(removeCoupon({
          id_store: order.store.id_store,
          id_order: order.id_order,
          coupon_code: value,
          action: 'removeCoupon',
        }, {
          msg_success: t('msg_success-coupon-removed'),
          msg_error: t('msg_error-coupon-removed'),
        }));
      } else {
        dispatch(removeCoupon({
          ...preorderData,
          id_store: order.store.id_store,
          id_order: order.id_order,
          coupon_code: value,
          action: 'removeCoupon',
        }, {
          msg_success: t('msg_success-coupon-removed'),
          msg_error: t('msg_error-coupon-removed'),
        }));
      }
    }
  };

  const handleApplyDiscount = () => {
    if (couponCode) {
      useTrack('cupon_aplicar', {
        pantalla: `Paso ${currentStep}`,
        merchant: order?.store?.store_name,
      });
      if (isAuth) {
        dispatch(addCoupon({
          id_order: order.id_order,
          coupon_code: couponCode,
          action: 'addCoupon',
        }, {
          msg_success: t('msg_success-coupon-added'),
          msg_error: t('msg_error-coupon-added'),
        }));
      } else {
        dispatch(addCoupon({
          ...preorderData,
          id_order: order.id_order,
          coupon_code: couponCode,
          action: 'addCoupon',
        }, {
          msg_success: t('msg_success-coupon-added'),
          msg_error: t('msg_error-coupon-added'),
        }));
      }
    }
  };

  useEffect(() => {
    if (checkoutStep === 1) {
      if (order?.store) {
        dispatch({
          type: 'SET_PREORDER_STORE',
          payload: {
            storeData: {
              id_store: order?.store?.id_store,
              Addresses_id_address: order?.store?.Addresses_id_address,
              store_name: order?.store?.store_name,
              store_url: order?.store?.store_url,
              currency: order?.store?.currency,
              logo_image: order?.store?.logo_image,
              phone_number: order?.store?.phone_number,
              status: order?.store?.status,
              country_name: order?.store?.country_name,
              confirmation_token_order: order?.store?.confirmation_token_order,
            },
          },
        });
      }
      if (order?.store?.products?.length > 0) {
        dispatch({
          type: 'SET_PREORDER_PRODUCTS',
          payload: order?.store?.products,
        });
      }
      if (order?.store?.coupons > 0) {
        dispatch({
          type: 'SET_PREORDER_COUPONS',
          payload: order?.store?.coupons?.coupons,
        });
      }
      if (order?.store?.coupons?.isFreeShipping) {
        dispatch({
          type: 'SET_PREORDER_FREESHIPPING',
          payload: order?.store?.coupons?.isFreeShipping,
        });
      }
      if (order.isDigital && order.isSubscription && !userData.isAuthenticated) {
        dispatch({
          type: 'SET_SAVE_USER_DATA',
          payload: true,
        });
      }
    }
  }, []);

  const handleSaveUserData = () => {
    if (saveUserData) {
      dispatch({
        type: 'SET_SAVE_USER_DATA',
        payload: false,
      });
      useTrack('checkbox_guardar_datos', {
        pantalla: 'Paso 4 - Resumen',
        merchant: order?.store?.store_name,
        selected: true,
      });
    } else {
      dispatch({
        type: 'SET_SAVE_USER_DATA',
        payload: true,
      });
      useTrack('checkbox_guardar_datos', {
        pantalla: 'Paso 4 - Resumen',
        merchant: order?.store?.store_name,
        selected: false,
      });
    }
  };

  const handleCheckoutComplete = async () => {
    dispatch(placeOrder(
      order?.id_order,
      preorderData,
      { msg_success: orderMsg },
      paymentMethod.gateway_name,
    ));
    useTrack('btn_pagar', {
      pantalla: 'Paso 4 - Resumen',
      merchant: order?.store?.store_name,
    });
  };

  return (
    <SummaryContainer active={isMobile ? (currentStep > 3 ? 1 : 0) : 1}>
      <Typography variant="h6" sx={fromModal ? { display: 'none' } : {}}>
        {t('summary-card')}
        <Typography component="span" sx={{ color: 'text.secondary' }}>
          &nbsp;
          (
          {t('summary-card-items', { totalItems })}
          {totalItems > 1 ? 's' : ''}
          )
        </Typography>
      </Typography>
      <SummaryWrapper>
        {order?.store?.products?.map((product) => (
          <SummaryProducts
            key={product.id_product}
            product={product}
            onDelete={() => handleDeleteCart(product.id_product)}
            onEdit={() => handleEditProduct(product.id_product)}
            onDecrease={() => handleDecreaseProduct(product.id_product)}
            onIncrease={() => handleIncreaseProduct(product.id_product)}
            isEdit={currentStep !== 5}
            placingOrder={order.confirmation?.loading || currentStep === 6}
            isSub={order?.isSubscription}
            fromModal
            currentStep={currentStep}
            paymentCheckout={paymentCheckout}
          />
        ))}
        <Divider />
        <SummaryCoupons
          currentStep={currentStep}
          setCoupon={setCouponCode}
          applyDiscount={handleApplyDiscount}
          deleteDiscount={handleDeleteDiscount}
          paymentCheckout={paymentCheckout}
        />
        {!paymentCheckout && <Divider />}
        <SummaryDataStack active={1}>
          <Typography variant="body2">
            {t('sub-total-label')}
          </Typography>
          <Typography variant="subtitle2">
            {fCurrency(order.subtotal_price)}
          </Typography>
        </SummaryDataStack>
        <SummaryDataStack active={order.total_discount ? 1 : 0}>
          <Typography variant="body2">
            {t('discount-label')}
          </Typography>
          <Typography variant="subtitle2">
            {order.total_discount ? fCurrency(-order.total_discount) : '-'}
          </Typography>
        </SummaryDataStack>
        <SummaryDataStack active={order.isDigital ? 0 : 1}>
          <Typography variant="body2">
            {t('shipping-label')}
          </Typography>
          <Typography
            variant="subtitle2"
            sx={(!order.shipment_cost && displayShipping)
              ? { color: '#637381' }
              : { color: '#484848' }}
          >
            {order.shipment_cost ? fCurrency(order.shipment_cost) : displayShipping}
          </Typography>
        </SummaryDataStack>
        <Divider />
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="body1" sx={{ fontWeight: 600 }}>{t('total-label')}</Typography>
          <Grid sx={{ textAlign: 'right' }}>
            <Typography variant="body1" sx={{ color: 'primary.main', fontWeight: 600 }}>
              {fCurrency(order.total_price)}
            </Typography>
            <Typography variant="caption" sx={{ fontStyle: 'italic' }}>
              {t('tax-label')}
            </Typography>
          </Grid>
        </Stack>
        <Stack
          sx={(isMobile && currentStep === 4)
            ? {
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              alignItems: 'center',
              width: '100%',
              paddingTop: '0.5rem',
              gap: '1rem',
            }
            : {
              display: 'none',
            }}
        >
          <SaveDataContainer sx={userData.isAuthenticated ? { display: 'none' } : {}}>
            <Checkbox
              disabled={order?.confirmation?.loading || (order.isDigital && order.isSubscription)}
              checked={saveUserData || (order.isDigital && order.isSubscription)}
              onClick={() => handleSaveUserData()}
            />
            <Typography variant="body2">
              {t('save-user-data')}
            </Typography>
          </SaveDataContainer>
          <LoadingButton
            fullWidth
            size="large"
            variant="contained"
            loading={order.confirmation.loading || order.loadingShipping}
            disabled={
              !order.isDigital && (
                (!order?.store?.id_shipping_method_external
                  || order?.store?.id_shipping_method_external === null)
                || order?.store?.shipping_methods?.length === 0
                || (
                  order?.store?.products
                  && Boolean(order?.store?.products.find((p) => p.loadingQuantity === true))
                )
                || order?.loadingShipping
                || order?.loadingDiscount
                || (Object.keys(order?.payment_method)?.length === 0
                  || order?.payment_method?.id_payment_gateway === 0)
              )
            }
            sx={{
              marginTop: 0,
              width: '100%',
              height: '4rem',
              gap: '0.5rem',
              borderRadius: '100px',
            }}
            onClick={() => handleCheckoutComplete()}
          >
            {order.isSubscription
              ? t('summary-subscribe')
              : t('summary-cta-label', { totalPrice })}
          </LoadingButton>
        </Stack>
        {(isMobile && currentStep === 5) && <ReturnToStore />}
      </SummaryWrapper>
    </SummaryContainer>
  );
}
