import {
  Button,
  Grid, keyframes, styled, Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import { getOrderStatus } from '../../redux/actions/ordersAction';
import LoadingScreen from '../LoadingScreen';
import PaymentFailureModal from '../Modals/PaymentFailureModal';
import PaymentCancelModal from '../Modals/PaymentCancelModal';

const fadeIn = keyframes`
  0% { opacity: 0 }
  100% { opacity: 1 }
`;

const NequiContainer = styled(Grid)(() => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '2.5rem',
  padding: '1rem',
}));

const NequiCard = styled('img')(() => ({
  animation: `${fadeIn} 2s ease-in-out`,
  boxShadow: '0px 8px 26px #0a0a0a33',
  border: '1px solid #0a0a0a33',
  borderRadius: '50px',
  maxWidth: '28rem',
  '@media (max-width: 600px)': {
    maxWidth: '100%',
  },
}));

function NequiConfirmation() {
  const dispatch = useDispatch();
  const orderData = useSelector((state) => state.orders);
  const loadingConfirmation = orderData?.confirmation?.loading;
  const { t } = useTranslation(['purchase']);
  const [failureOpen, setFailureOpen] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const redirectLinkCancel = orderData?.redirect_link_cancel || orderData?.store?.store_url;
  const redirectLinkFailure = orderData?.redirect_link_failure || orderData?.store?.store_url;

  const [minutes, setMinutes] = useState(4);
  const [seconds, setSeconds] = useState(59);
  const timer = `0${minutes}:${seconds >= 10 ? seconds : `0${seconds}`}`;

  const handlePaymentConfirm = () => {
    dispatch(getOrderStatus(
      orderData?.confirmation?.id_order,
      {
        msg_success: t('msg_success-order-processed'),
        msg_pending: t('msg_payment-pending'),
        msg_processing: t('msg_payment-processing'),
      },
      setFailureOpen,
    ));
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (minutes > 0 && seconds === 0) {
        setMinutes(minutes - 1);
        setSeconds(59);
      }
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (minutes === 0 && seconds === 0) {
        setMinutes(4);
        setSeconds(59);
        handlePaymentConfirm();
      }
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [seconds]);

  return (
    <>
      <PaymentFailureModal
        open={failureOpen}
        redirect={redirectLinkFailure}
      />
      <PaymentCancelModal
        open={cancelModal}
        handleModal={setCancelModal}
        redirect={redirectLinkCancel}
      />
      {orderData?.confirmation?.loading && !orderData?.confirmation?.id_order
        ? <LoadingScreen />
        : (
          <NequiContainer>
            <Typography variant="h5" sx={{ color: 'text.primary', fontWeight: 'bold' }}>
              Confirma la transacción en la app
            </Typography>
            <NequiCard src="https://wava-assets.s3.amazonaws.com/brand/nequi-push-notification.gif" alt="Nequi gif" />
            <Typography variant="body1" sx={{ color: 'text.secondary', textAlign: 'center' }}>
              Recibirás una notificación por el valor de tu compra en la app de Nequi.
              <br />
              Tu compra se acreditará una vez confirmes el pago.
            </Typography>
            <Typography variant="body1" sx={{ color: 'text.secondary', textAlign: 'center' }}>
              {`Validación de pago automática en ${timer}`}
            </Typography>
            <LoadingButton
              fullWidth
              size="large"
              variant="contained"
              loading={loadingConfirmation}
              disabled={failureOpen || cancelModal}
              sx={{ width: { xs: '100%', sm: '24rem' }, borderRadius: '6rem' }}
              onClick={() => handlePaymentConfirm()}
            >
              Confirmar pago
            </LoadingButton>
            <Button
              sx={{
                display: redirectLinkCancel ? 'flex' : 'none',
                px: '2rem',
                borderRadius: '6.25rem',
                textTransform: 'none',
                alignSelf: 'center',
              }}
              variant="outlined"
              size="large"
              onClick={() => setCancelModal(true)}
            >
              {t('Cancelar compra')}
            </Button>
          </NequiContainer>
        )}
    </>
  );
}

export default NequiConfirmation;
