import React from 'react';
import { Button, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { DialogContentStyled, DialogStyled, ModalStack } from './modal.styled';
import alertIcon from '../../assets/modal_alert_icon.svg';

export default function PaymentCancelModal({ open, handleModal, redirect }) {
  const { t } = useTranslation(['modals']);

  const handleCancel = () => {
    sessionStorage.removeItem('cc');
    sessionStorage.removeItem('billing');
    window.location.reload();
  };

  const handleRedirect = () => {
    sessionStorage.removeItem('cc');
    sessionStorage.removeItem('billing');
    if (redirect) {
      window.parent.location.href = redirect;
    }
  };

  return (
    <DialogStyled open={open}>
      <DialogContentStyled
        sx={{
          padding: '1rem 2rem 2rem 2rem',
          justifyContent: 'center',
          alignItems: 'flex-start',
        }}
      >
        <ModalStack>
          <IconButton
            sx={{ alignSelf: 'flex-end' }}
            onClick={() => handleModal(false)}
          >
            <CloseIcon />
          </IconButton>
          <img src={alertIcon} alt="Failure icon" width="48px" />
          <Typography variant="h5">
            {t('Estás a punto de cancelar tu pago')}
          </Typography>
          <Typography variant="body2">
            {t('Al hacer esto ya no podrás hacer el pago y deberás hacer el proceso nuevamente para confirmar la compra.')}
          </Typography>
        </ModalStack>
        <ModalStack width="100%" sx={{ gap: '1rem' }}>
          <Button
            sx={{
              px: '2rem',
              borderRadius: '6.25rem',
              textTransform: 'none',
              alignSelf: 'center',
            }}
            variant="contained"
            size="large"
            onClick={() => handleCancel()}
          >
            {t('Intentar con otra forma de pago')}
          </Button>
          <Button
            sx={{
              display: redirect ? 'flex' : 'none',
              px: '2rem',
              borderRadius: '6.25rem',
              textTransform: 'none',
              alignSelf: 'center',
            }}
            variant="outlined"
            size="large"
            onClick={() => handleRedirect()}
          >
            {t('Regresar a la tienda')}
          </Button>
        </ModalStack>
      </DialogContentStyled>
    </DialogStyled>
  );
}
