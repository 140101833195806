import { Grid, Stack, styled } from '@mui/material';

export const SummarySection = styled(Grid)(() => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%',
  paddingTop: '3rem',
  '@media (max-width: 780px)': {
    paddingTop: '1rem',
  },
}));

export const SummaryContainer = styled(Grid)(({ active }) => ({
  display: active ? 'flex' : 'none',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'stretch',
  width: '100%',
  paddingBottom: 0,
  '@media (max-width: 780px)': {
    paddingBottom: '2rem',
  },
}));

export const SummaryWrapper = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'stretch',
  width: '100%',
  gap: '1rem',
}));

export const SummaryCouponsContainer = styled(Grid)(({ active }) => ({
  display: active ? 'flex' : 'none',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'stretch',
  width: '100%',
  gap: '1rem',
}));

export const SummaryCouponsStack = styled(Stack)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%',
  gap: '0.75rem',
  flexWrap: 'nowrap',
}));

export const ChipContainer = styled(Grid)(() => ({
  display: 'flex',
  flex: 1,
  width: '100%',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: '0.5rem',
}));

export const SummaryDataStack = styled(Stack)(({ active }) => ({
  display: active ? 'flex' : 'none',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
}));

// Mobile

export const SummaryCard = styled(Grid)(({ active }) => ({
  display: active ? 'flex' : 'none',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%',
  padding: '1rem 0rem 0.5rem 0rem',
  gap: '0.5rem',
}));

export const ProductImageMobile = styled('img')(() => ({
  width: '2.875rem',
  height: '2.875rem',
  borderRadius: '1rem',
}));

export const ProductStackMobile = styled(Stack)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'stretch',
  width: '100%',
}));
