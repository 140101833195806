/* eslint-disable camelcase */
import React from 'react';
import { Stack, Typography, useMediaQuery } from '@mui/material';
// import AddIcon from '@mui/icons-material/Add';
// import RemoveIcon from '@mui/icons-material/Remove';
import InfoIcon from '@mui/icons-material/Info';
import { useTranslation } from 'react-i18next';
import {
  ProductContainer, ProductData, ProductDataStack, ProductImage, ProductOptions,
} from './summaryproducts.styled';
import { fCurrency } from '../../../../utils/formatNumber';

function SummaryProducts({
  product,
  // onDelete,
  // onEdit,
  // isEdit,
  // onDecrease,
  // onIncrease,
  // placingOrder,
  isSub,
  fromModal,
  currentStep,
  // paymentCheckout,
}) {
  const {
    name,
    current_price,
    images,
    // model,
    // quantity,
    variants = false,
    // loadingQuantity,
    // stock,
  } = product;
  const productName = name + (variants ? ` ( ${variants.map((variant) => variant.value).join(' / ')} )` : '');
  const { t } = useTranslation(['guest-checkout']);
  const isMobile = useMediaQuery('(max-width:1080px)');

  return (
    <ProductContainer sx={fromModal && currentStep !== 4 && currentStep !== 5 && isMobile ? { paddingTop: '0rem' } : {}}>
      <ProductImage
        src={images.find((image) => image.is_default)?.image_url
          || 'https://wava-products.s3.amazonaws.com/store_logos/common-cart.webp'}
        alt="product"
      />
      <ProductData>
        <ProductDataStack>
          <Stack
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
              flexWrap: (fromModal && isMobile) ? 'wrap' : 'nowrap',
            }}
          >
            <Typography
              variant="body2"
              sx={{
                maxWidth: {
                  xs: 130, sm: 400, md: 600, lg: 800,
                },
              }}
            >
              {productName}
            </Typography>
            <Typography
              sx={!isMobile ? { display: 'none' } : {}}
              alignSelf="end"
              variant="subtitle2"
            >
              {fCurrency(current_price)}
            </Typography>
          </Stack>
          {/* {(isSub && isMobile)
            ? (
              <Stack
                direction="row"
                alignItems="center"
                gap="0.25rem"
                pb={1}
              >
                <InfoIcon color="info" />
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {t('product-subscription')}
                </Typography>
              </Stack>
            )
            : (
              <Typography variant="caption">
                {model && `Model: ${model}`}
              </Typography>
            )}
          {!isEdit
          && (
          <Typography
            variant="caption"
            sx={paymentCheckout ? { display: 'none ' } : { color: 'text.secondary' }}
          >
            {`${t('summary-quantity')} ${quantity}`}
          </Typography>
          )} */}
        </ProductDataStack>
        {/* {isEdit
          && (
            <ProductQuantity sx={paymentCheckout ? { display: ' none' } : {}}>
              <IconButton
                size="small"
                onClick={() => onDecrease()}
                disabled={loadingQuantity || quantity <= 1 || placingOrder}
              >
                <RemoveIcon />
              </IconButton>
              {loadingQuantity ? <CircularProgress size={22} /> : quantity}
              <IconButton
                size="small"
                onClick={() => onIncrease()}
                disabled={Boolean(loadingQuantity || (stock && quantity >= stock)) || placingOrder}
              >
                <AddIcon />
              </IconButton>
            </ProductQuantity>
          )} */}
      </ProductData>
      {!isMobile
        && (
          <ProductOptions sx={isMobile ? { display: 'none' } : {}}>
            <Typography
              alignSelf="end"
              variant="subtitle2"
              noWrap
            >
              {fCurrency(current_price)}
            </Typography>
            {isSub
              && (
                <Stack direction="row" alignItems="center" gap="0.25rem">
                  <InfoIcon color="info" />
                  <Typography noWrap variant="body2" sx={{ color: 'text.secondary' }}>
                    {t('product-subscription')}
                  </Typography>
                </Stack>
              )}
          </ProductOptions>
        )}
    </ProductContainer>
  );
}

export default SummaryProducts;
