export default function decodeUTF8(value) {
  let encodedStr = '';
  let decodedStr = '';
  if (encodeURIComponent(escape(value))) {
    encodedStr = encodeURIComponent(escape(value));
    if (encodedStr && decodeURIComponent(unescape(encodedStr))) {
      decodedStr = decodeURIComponent(unescape(encodedStr));
      return decodedStr;
    }
    return value;
  }
  return value;
}
