/* eslint-disable max-len */
import {
  Stack, Typography, Grid, AccordionDetails, IconButton, Radio, Tooltip, Link, Button,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { LoadingButton } from '@mui/lab';
import PaymentsIcon from '../../../assets/PaymentsIcon';
import IntegrationNequi from '../../Nequi/IntegrationNequi';
import VGSForm from '../VGSForm';
import { deleteCard, setDefault } from '../../../redux/actions/paymentsAction';
import {
  AccordionStyled,
  AccordionSummaryContainer,
  AccordionSummaryStyled,
  AccordionsContainer,
  AddCardContainer,
  BankDataStack,
  CardIconsContainer,
  CreditCardContainer,
  CreditCardStack,
  UserPaymentContainer,
} from './userpayment.styled';
import useTrack from '../../../hooks/useTrack';
import { fCurrency } from '../../../utils/formatNumber';
import { placeOrder } from '../../../redux/actions/ordersAction';
import PayConfirmationModal from '../../Modals/PayConfirmationModal';
import PaymentCancelModal from '../../Modals/PaymentCancelModal';
import CreditCardAddress from './CreditCardAddress';

function UserPayment({
  defaultPayment, submitCreditCard, paymentMethods, stepTitle, fromLastStep, formIncomplete, sessionData,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation(['credit-card']);
  const userData = useSelector((state) => state.user);
  const billingAddressSessionData = JSON.parse(sessionStorage.getItem('billing'));
  const billingAddress = Object.keys(userData?.addresses).length > 0 ? userData?.addresses : billingAddressSessionData;
  const order = useSelector((state) => state.orders);
  const preorderData = useSelector((state) => state.preorder);

  const [cancelModal, setCancelModal] = useState(false);
  const redirectLinkCancel = order?.redirect_link_cancel || order?.store?.store_url;

  const totalPrice = fCurrency(order.total_price);
  const paymentMethod = order?.payment_method;
  const storeName = order?.store?.store_name;
  const checkoutStep = useSelector((state) => state.checkoutStep);
  const paymentGateway = useSelector((state) => state.orders.payment_method.id_payment_gateway);
  const cardMethod = paymentMethods?.find((method) => (method?.gateway_type === 'card'));

  const nequiMethod = paymentMethods?.find((method) => (method?.gateway_type === 'nequi'));
  const acceptsNequi = paymentMethods?.map((method) => (method?.gateway_name === 'Nequi')).includes(true);
  const [nequiAccordion, setNequiAccordion] = useState(false);

  const transferData = paymentMethods?.find((method) => (method?.gateway_name === 'Bank Transfer'));
  const acceptsBankTransfer = paymentMethods?.map((method) => (method?.gateway_name === 'Bank Transfer')).includes(true);
  const [bankAccordion, setBankAccordion] = useState(false || paymentGateway === transferData?.id_payment_gateway);
  const accountNumber = transferData && transferData?.bank_data?.['Acount No.'];
  const bankName = transferData && transferData?.bank_data?.['Bank Name'];
  const bankAddress = transferData && transferData?.bank_data?.Address;
  const bankSwift = transferData && transferData?.bank_data?.SWIFT;
  const acceptsCash = paymentMethods?.map((method) => (method?.gateway_name === 'Cash')).includes(true);
  const cashData = paymentMethods?.find((method) => (method?.gateway_name === 'Cash'));

  const [singlePaymentMethod, setSinglePaymentMethod] = useState(
    (paymentMethods?.length === 1 || Boolean(defaultPayment)) && paymentGateway === cardMethod?.id_payment_gateway,
  );
  const [showVGSForm, setShowVGSForm] = useState(false);

  const handlePaymentSelection = () => {
    setNequiAccordion(false);
    if (checkoutStep === 3) {
      useTrack('pago_tarjeta', {
        pantalla: 'Payment checkout',
        merchant: storeName,
      });
    }
    if (defaultPayment) {
      if (userData?.isAuthenticated) {
        dispatch({
          type: 'SET_PAYMENT_METHOD',
          payload: {
            ...cardMethod,
            id_credit_card: defaultPayment.id_credit_card,
          },
        });
        dispatch({
          type: 'SET_PREORDER_PAYMENT',
          payload: {
            ...cardMethod,
            id_credit_card: defaultPayment.id_credit_card,
          },
        });
      } else {
        dispatch({
          type: 'SET_PAYMENT_METHOD',
          payload: {
            ...cardMethod,
            id_credit_card_external: defaultPayment.id_external,
          },
        });
        dispatch({
          type: 'SET_PREORDER_PAYMENT',
          payload: {
            ...cardMethod,
            id_credit_card_external: defaultPayment.id_external,
          },
        });
      }
    }
  };

  const nequiContinue = () => {
    dispatch({
      type: 'SET_PAYMENT_METHOD',
      payload: {
        ...nequiMethod,
      },
    });
    dispatch({
      type: 'SET_PREORDER_PAYMENT',
      payload: {
        ...nequiMethod,
      },
    });
    if (checkoutStep === 3) {
      useTrack('pago_nequi', {
        pantalla: 'Payment checkout',
        merchant: storeName,
      });
    }
  };

  const handleBankTransfer = () => {
    dispatch({
      type: 'SET_PAYMENT_METHOD',
      payload: {
        ...transferData,
      },
    });
    dispatch({
      type: 'SET_PREORDER_PAYMENT',
      payload: {
        ...transferData,
      },
    });
    if (checkoutStep === 3) {
      useTrack('pago_transferencia_bancaria', {
        pantalla: 'Payment checkout',
        merchant: storeName,
      });
    }
  };

  const handleDelete = (cc) => {
    dispatch(deleteCard(
      userData,
      cc.id_credit_card,
      {
        msg_success: t('message-delete-card-success'),
        msg_error: t('message-delete-card-error'),
      },
    ));
  };

  const handleDefault = (cc) => {
    dispatch(setDefault(
      userData,
      {
        id_credit_card: cc.id_credit_card,
        type: 'shipping',
      },
      {
        msg_success: t('message-set-default-success'),
        msg_error: t('message-set-default-error'),
      },
    ));
  };

  const handleAccordions = (openAccordion) => {
    switch (openAccordion) {
      case 'creditCard':
        setSinglePaymentMethod(true);
        setNequiAccordion(false);
        setBankAccordion(false);
        break;
      case 'nequi':
        setNequiAccordion(true);
        setSinglePaymentMethod(false);
        setBankAccordion(false);
        break;
      case 'bank':
        setBankAccordion(true);
        setSinglePaymentMethod(false);
        setNequiAccordion(false);
        break;
      default:
        break;
    }
  };

  const handleCopyData = (data) => {
    navigator.clipboard.writeText(data);
    dispatch({
      type: 'SET_MESSAGE',
      payload: { text: t('copied-to-clipboard'), kind: 'success' },
    });
  };

  const handleCashPayment = () => {
    setSinglePaymentMethod(false);
    setNequiAccordion(false);
    setBankAccordion(false);
    dispatch({
      type: 'SET_PAYMENT_METHOD',
      payload: {
        ...cashData,
      },
    });
    dispatch({
      type: 'SET_PREORDER_PAYMENT',
      payload: {
        ...cashData,
      },
    });
    if (checkoutStep === 3) {
      useTrack('pago_transferencia_bancaria', {
        pantalla: 'Payment checkout',
        merchant: storeName,
      });
    }
  };

  const handleResetPayment = () => {
    dispatch({ type: 'SET_USER_RESET_CC' });
    dispatch({ type: 'SET_PAYMENT_RESET' });
    dispatch({ type: 'SET_PREORDER_PAYMENT_RESET' });
    sessionStorage.removeItem('cc');
    sessionStorage.removeItem('billing');
    dispatch({
      type: 'SET_MESSAGE',
      payload: { text: 'Datos de tarjeta eliminados', kind: 'success' },
    });
  };

  const resetBillingAddress = () => {
    sessionStorage.removeItem('billing');
    dispatch({
      type: 'SET_USER_DATA',
      payload: { addresses: [] },
    });
    dispatch({
      type: 'SET_PREORDER_SHOPPER_ADDRESS',
      payload: [],
    });
    dispatch({
      type: 'SET_PREORDER_ADDRESS',
      payload: [],
    });
    dispatch({
      type: 'SET_MESSAGE',
      payload: { text: 'Dirección de tarjeta eliminada', kind: 'success' },
    });
  };

  const handleCheckoutComplete = async (tokenCode) => {
    dispatch(placeOrder(
      order?.id_order,
      {
        ...preorderData,
        shopper: { ...userData },
        confirmation_token: tokenCode,
      },
      { msg_success: t('msg_success-order-processed') },
      paymentMethod.gateway_name,
    ));
    useTrack('btn_pagar', {
      pantalla: 'Payment checkout',
      merchant: storeName,
    });
  };

  useEffect(() => {
    if (order.isDigital && paymentMethod) {
      dispatch({
        type: 'SET_PREORDER_DATA',
        payload: {
          shopper: {
            ...userData,
            addresses: !order.isDigital && (userData?.addresses.length > 0
              ? userData?.addresses?.find((address) => address.type === 'shipping')
              : userData?.addresses),
          },
          payment_method: defaultPayment?.id_credit_card && paymentMethod.id_payment_gateway !== 2
            ? (userData.isAuthenticated
              ? {
                id_payment_gateway: 1,
                gateway_name: 'Stripe',
                id_credit_card: defaultPayment?.id_credit_card,
              } : {
                id_payment_gateway: 1,
                gateway_name: 'Stripe',
                id_credit_card_external: defaultPayment?.id_credit_card,
              }
            )
            : { ...paymentMethod },
          shipping_address: !order.isDigital && (userData?.addresses.length > 0
            ? userData?.addresses?.find((address) => address.type === 'shipping')
            : userData?.addresses),
          storeData: {
            id_store: order?.store?.id_store,
            Addresses_id_address: order?.store?.Addresses_id_address,
            store_name: order?.store?.store_name,
            store_url: order?.store?.store_url,
            currency: order?.store?.currency,
            logo_image: order?.store?.logo_image,
            phone_number: order?.store?.phone_number,
            status: order?.store?.status,
            confirmation_token_order: order?.store?.confirmation_token_order,
          },
          shipment_cost: order?.shipment_cost,
          total_price: order?.total_price,
          totalItems: order?.totalItems,
          total_discount: order?.total_discount,
          subtotal_price: order?.subtotal_price,
          registeredUser: order?.registeredUser,
          products: order?.store?.products,
          isFreeShipping: order?.isFreeShipping,
          coupons: order?.coupons,
          isDigital: order?.isDigital,
        },
      });
    }
    if (paymentMethods?.length === 1) {
      dispatch({
        type: 'SET_PAYMENT_METHOD',
        payload: {
          ...paymentMethods[0],
        },
      });
      dispatch({
        type: 'SET_PREORDER_PAYMENT',
        payload: {
          ...paymentMethods[0],
        },
      });
    }
    useTrack('payment_checkout', {
      pantalla: 'Payment checkout',
      merchant: storeName,
    });
  }, []);

  return (
    <UserPaymentContainer>
      <PayConfirmationModal
        userEmail={userData.email}
        loading={order.confirmation.loading || order.loadingShipping}
        completeCheckout={handleCheckoutComplete}
      />
      <PaymentCancelModal
        open={cancelModal}
        handleModal={setCancelModal}
        redirect={redirectLinkCancel}
      />
      <Typography variant="h6" sx={fromLastStep ? { display: 'none' } : {}}>
        {stepTitle}
      </Typography>
      <AccordionsContainer modaledit={fromLastStep ? 1 : 0}>
        {cardMethod
          && (
            <AccordionStyled
              disableGutters
              disabled={formIncomplete}
              expanded={formIncomplete ? false : (singlePaymentMethod || paymentMethods?.length === 1)}
              onChange={() => handleAccordions('creditCard')}
            >
              <AccordionSummaryStyled aria-controls="panel1bh-content" id="panel1bh-header">
                <AccordionSummaryContainer>
                  <Grid container direction="row" gap="0.5rem" alignItems="center" sx={{ flexWrap: 'nowrap' }}>
                    <Grid item alignItems="center">
                      <Radio
                        disabled={formIncomplete}
                        checked={paymentGateway === cardMethod?.id_payment_gateway
                          || (paymentGateway === cardMethod?.id_payment_gateway && sessionData)}
                        onClick={() => handlePaymentSelection()}
                      />
                    </Grid>
                    <Stack direction="column">
                      <Typography variant="body2" sx={{ color: 'text.primary' }}>
                        {t('payment-option')}
                      </Typography>
                      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        {t('payment-supports')}
                      </Typography>
                    </Stack>
                  </Grid>
                  <CardIconsContainer>
                    <PaymentsIcon brand="mastercard" width="30px" height="30px" />
                    <PaymentsIcon brand="visa2" width="36px" height="36px" />
                  </CardIconsContainer>
                </AccordionSummaryContainer>
              </AccordionSummaryStyled>
              <AccordionDetails>
                {!defaultPayment || showVGSForm ? (
                  <VGSForm
                    submit={(showVGSForm && userData.isAuthenticated) ? false : submitCreditCard}
                    cta_label={t('cta-on-checkout')}
                    showform={showVGSForm}
                    hideform={setShowVGSForm}
                  />
                ) : (
                  !userData.isAuthenticated
                    ? (
                      <Stack direction="column" gap={1}>
                        <CreditCardContainer>
                          <CreditCardStack>
                            <PaymentsIcon
                              brand={defaultPayment?.brand}
                              width="24px"
                              alignItems="center"
                              flexDirection="column"
                            />
                            <Typography variant="subtitle2" color="text.primary" sx={{ textTransform: 'capitalize' }}>
                              {`${defaultPayment?.brand} •••• ${defaultPayment?.last_four}`}
                            </Typography>
                          </CreditCardStack>
                          <IconButton size="small" onClick={() => handleResetPayment()}>
                            <ClearIcon color="primary" />
                          </IconButton>
                        </CreditCardContainer>
                        {billingAddress
                          ? (
                            <Stack direction="column" gap={1} mx={1}>
                              <Typography variant="body1">
                                Dirección de facturación:
                              </Typography>
                              <Stack direction="row" gap={1} alignItems="center" justifyContent="space-between" width="100%">
                                <Stack direction="row" gap={1} alignItems="center">
                                  <LocationOnIcon />
                                  <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                    {`${billingAddress?.address_field_1}, ${billingAddress?.city_name}, ${billingAddress?.country_name} `}
                                  </Typography>
                                </Stack>
                                <Typography
                                  variant="body2"
                                  sx={{
                                    fontWeight: 'bold',
                                    cursor: 'pointer',
                                    textDecoration: 'underline',
                                  }}
                                  onClick={() => resetBillingAddress()}
                                >
                                  Cambiar
                                </Typography>
                              </Stack>
                            </Stack>
                          )
                          : <CreditCardAddress address={billingAddressSessionData} />}
                      </Stack>
                    )
                    : (userData.payment_methods[0]?.credit_cards
                      && userData.payment_methods[0]?.credit_cards.map((cc) => (
                        <CreditCardContainer key={cc.id_credit_card}>
                          <CreditCardStack>
                            <Radio checked={cc?.is_default === 1} onClick={() => handleDefault(cc)} />
                            <PaymentsIcon
                              brand={cc?.brand}
                              width="24px"
                              alignItems="center"
                              flexDirection="column"
                            />
                            <Typography variant="subtitle2" color="text.primary" sx={{ textTransform: 'capitalize' }}>
                              {`${cc?.brand} •••• ${cc?.last_four}`}
                            </Typography>
                          </CreditCardStack>
                          <CreditCardStack>
                            <IconButton onClick={() => handleDelete(cc)}>
                              <ClearIcon color="primary" />
                            </IconButton>
                          </CreditCardStack>
                        </CreditCardContainer>
                      ))
                    )
                )}
                {
                  (defaultPayment && userData.isAuthenticated && !showVGSForm)
                  && (
                    <AddCardContainer sx={{ cursor: 'pointer' }} onClick={() => setShowVGSForm(true)}>
                      <AddIcon color="primary" />
                      <Typography variant="body1" component="h1" color="primary">
                        {t('cc-add')}
                      </Typography>
                    </AddCardContainer>
                  )
                }
              </AccordionDetails>
            </AccordionStyled>
          )}
        {acceptsNequi
          && (
            <AccordionStyled
              disableGutters
              disabled={formIncomplete}
              sx={{ borderRadius: 1 }}
              expanded={nequiAccordion || paymentMethods?.length === 1}
            >
              <AccordionSummaryStyled aria-controls="panel1bh-content" id="panel1bh-header">
                <AccordionSummaryContainer
                  sx={paymentMethods?.length === 1 ? { cursor: 'default' } : {}}
                  onClick={() => handleAccordions('nequi')}
                >
                  <Grid container direction="row" gap="0.5rem" alignItems="center">
                    <Grid item alignItems="center">
                      <Radio
                        disabled={formIncomplete}
                        checked={paymentGateway === nequiMethod?.id_payment_gateway}
                        onClick={() => nequiContinue()}
                      />
                    </Grid>
                    <Stack direction="column">
                      <Typography variant="body2" sx={{ color: 'text.primary' }}>
                        {t('nequi-title')}
                      </Typography>
                      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        {t('nequi-subtitle')}
                      </Typography>
                    </Stack>
                  </Grid>
                  <PaymentsIcon brand="nequiLogo" width="70px" />
                </AccordionSummaryContainer>
              </AccordionSummaryStyled>
              <AccordionDetails>
                <IntegrationNequi
                  handleAccordion={setNequiAccordion}
                  formIncomplete={formIncomplete}
                />
              </AccordionDetails>
            </AccordionStyled>
          )}
        {acceptsBankTransfer
          && (
            <AccordionStyled
              disableGutters
              disabled={formIncomplete}
              sx={{ borderRadius: 1 }}
              expanded={bankAccordion || paymentMethods?.length === 1}
            >
              <AccordionSummaryStyled aria-controls="panel1bh-content" id="panel1bh-header">
                <AccordionSummaryContainer onClick={() => handleAccordions('bank')}>
                  <Grid container direction="row" gap="0.5rem" alignItems="center">
                    <Grid item alignItems="center">
                      <Radio
                        disabled={formIncomplete}
                        checked={paymentGateway === transferData?.id_payment_gateway}
                        onClick={() => handleBankTransfer()}
                      />
                    </Grid>
                    <Stack direction="column">
                      <Typography variant="body2" sx={{ color: 'text.primary' }}>
                        {t('bank-transfer')}
                      </Typography>
                      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        {t('bank-data')}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid>
                    <AccountBalanceIcon />
                  </Grid>
                </AccordionSummaryContainer>
              </AccordionSummaryStyled>
              <AccordionDetails sx={{ display: 'flex', flexDirection: 'column', gap: '0.2rem' }}>
                <BankDataStack active={accountNumber ? 1 : 0}>
                  <Typography variant="body2">
                    {t('bank-account')}
                  </Typography>
                  <Typography id="accNumber" variant="body2" sx={{ fontWeight: 'bold' }}>
                    {accountNumber}
                  </Typography>
                  <Tooltip arrow placement="top" title={t('copy-to-clipboard')}>
                    <IconButton onClick={() => handleCopyData(accountNumber.toString())}>
                      <ContentCopyIcon style={{ fontSize: '15px' }} />
                    </IconButton>
                  </Tooltip>
                </BankDataStack>
                <BankDataStack active={bankName ? 1 : 0}>
                  <Typography variant="body2">
                    {t('bank-name')}
                  </Typography>
                  <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                    {bankName}
                  </Typography>
                  <Tooltip arrow placement="top" title={t('copy-to-clipboard')}>
                    <IconButton onClick={() => handleCopyData(bankName)}>
                      <ContentCopyIcon style={{ fontSize: '15px' }} />
                    </IconButton>
                  </Tooltip>
                </BankDataStack>
                <BankDataStack active={bankAddress ? 1 : 0}>
                  <Typography variant="body2">
                    {t('bank-address')}
                  </Typography>
                  <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                    {bankAddress}
                  </Typography>
                  <Tooltip arrow placement="top" title={t('copy-to-clipboard')}>
                    <IconButton onClick={() => handleCopyData(bankAddress)}>
                      <ContentCopyIcon style={{ fontSize: '15px' }} />
                    </IconButton>
                  </Tooltip>
                </BankDataStack>
                <BankDataStack active={bankSwift ? 1 : 0}>
                  <Typography variant="body2">
                    {t('bank-swift')}
                  </Typography>
                  <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                    {bankSwift}
                  </Typography>
                  <Tooltip arrow placement="top" title={t('copy-to-clipboard')}>
                    <IconButton onClick={() => handleCopyData(bankSwift)}>
                      <ContentCopyIcon style={{ fontSize: '15px' }} />
                    </IconButton>
                  </Tooltip>
                </BankDataStack>
              </AccordionDetails>
            </AccordionStyled>
          )}
        {acceptsCash
          && (
            <AccordionStyled
              disableGutters
              disabled={formIncomplete}
              sx={{ borderRadius: 1 }}
            >
              <AccordionSummaryStyled aria-controls="panel1bh-content" id="panel1bh-header">
                <AccordionSummaryContainer>
                  <Grid container direction="row" gap="0.5rem" alignItems="center">
                    <Grid item alignItems="center">
                      <Radio
                        disabled={formIncomplete}
                        checked={paymentGateway === cashData?.id_payment_gateway}
                        onClick={() => handleCashPayment()}
                      />
                    </Grid>
                    <Stack direction="column">
                      <Typography variant="body2" sx={{ color: 'text.primary' }}>
                        {t('cash-title')}
                      </Typography>
                      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        {t('cash-subtitle')}
                      </Typography>
                    </Stack>
                  </Grid>
                  <MonetizationOnIcon />
                </AccordionSummaryContainer>
              </AccordionSummaryStyled>
            </AccordionStyled>
          )}
      </AccordionsContainer>
      <Stack direction="column" gap={2}>
        <LoadingButton
          fullWidth
          size="large"
          variant="contained"
          loading={order.confirmation.loading || order.loadingShipping}
          disabled={
            !order.isDigital || (
              (order?.store?.products
                && Boolean(order?.store?.products?.find((p) => p.loadingQuantity === true)))
              || order?.loadingShipping
              || order?.loadingDiscount
              || (Object.keys(order?.payment_method)?.length === 0
                || order?.payment_method?.id_payment_gateway === 0)
              || formIncomplete
              || (defaultPayment && Boolean(!billingAddressSessionData))
            )
          }
          sx={{
            height: '3.5rem',
            borderRadius: '100px',
            marginTop: '2rem',
          }}
          onClick={() => handleCheckoutComplete()}
        >
          {t('payment-cta-label', { totalPrice })}
        </LoadingButton>
        <Button
          sx={{
            display: redirectLinkCancel ? 'flex' : 'none',
            px: '2rem',
            borderRadius: '6.25rem',
            textTransform: 'none',
            alignSelf: 'center',
          }}
          variant="text"
          size="large"
          onClick={() => setCancelModal(true)}
        >
          {t('Cancelar compra')}
        </Button>
        <Typography variant="body2" paragraph align="center" color="#637381">
          <Trans
            i18nKey="legals"
            components={[
              <Link target="_blank" href="https://wava.co/terminos-y-condiciones/" />,
              <Link target="_blank" href="https://wava.co/politica-de-privacidad/" />,
            ]}
          />
        </Typography>
      </Stack>
    </UserPaymentContainer>
  );
}

export default UserPayment;
