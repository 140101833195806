const initialState = {
  stores: [],
  selectedStore: null,
  draftStore: null,
  integrationRedirect: '',
  integrationsBody: null,
  integrationStatusLoading: false,
  inactiveStore: false,
  loadingStores: false,
  loadingLinks: false,
  loadingWebhook: false,
  loadingStoreLogo: false,
  loadingTnImages: false,
  tnImages: [],
  welcomeTour: {
    tour_step: 0,
    tour_complete: false,
  },
};

export default function index(state = initialState, action) {
  switch (action.type) {
    case 'SET_STORES':
      return {
        ...state,
        stores: action.payload,
      };
    case 'SET_SELECTED_STORE':
      return {
        ...state,
        selectedStore: action.payload,
      };
    case 'SET_DRAFT_STORE':
      return {
        ...state,
        draftStore: action.payload,
      };
    case 'SET_LOADING_LINKS':
      return {
        ...state,
        loadingLinks: action.payload,
      };
    case 'SET_INTEGRATION_REDIRECT':
      return {
        ...state,
        integrationRedirect: action.payload,
      };
    case 'SET_INTEGRATIONS_BODY':
      return {
        ...state,
        integrationsBody: {
          ...state.integrationsBody,
          ...action.payload,
        },
      };
    case 'SET_INTEGRATION_STATUS_LOADING':
      return {
        ...state,
        integrationStatusLoading: action.payload,
      };
    case 'SET_STORE_ORDERS':
      return {
        ...state,
        stores: state.stores.map((store) => (store.id_store === action.storeId ? {
          ...store,
          ordersList: action.payload,
        }
          : {
            ...store,
          })),
      };
    case 'SET_INACTIVE_STORE':
      return {
        ...state,
        inactiveStore: action.payload,
      };
    case 'SET_LOADING_STORES':
      return {
        ...state,
        loadingStores: action.payload,
      };
    case 'SET_LOADING_WEBHOOK':
      return {
        ...state,
        loadingWebhook: action.payload,
      };
    case 'SET_WELCOME_TOUR':
      return {
        ...state,
        welcomeTour: action.payload,
      };
    case 'SET_LOADING_TN_IMAGES':
      return {
        ...state,
        loadingTnImages: action.payload,
      };
    case 'SET_TN_IMAGES':
      return {
        ...state,
        tnImages: action.payload,
      };
    case 'SET_LOADING_STORE_LOGO':
      return {
        ...state,
        loadingStoreLogo: action.payload,
      };
    case 'STORE_LOGOUT':
      return initialState;
    default:
      return state;
  }
}
