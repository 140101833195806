import React from 'react';
import {
  CircularProgress,
  IconButton, Stack, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Close } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import AuthCode from '../Login/AuthCode';
import {
  DialogContentStyled, DialogStyled, ModalCancelButton,
} from './modal.styled';
import { SpanStyled } from '../StepsCheckout/stepscheckout.styled';

function PayConfirmationModal({ userEmail, loading, completeCheckout }) {
  const dispatch = useDispatch();
  const tokenRequired = useSelector((state) => state.tokenRequired?.status);
  const tokenError = useSelector((state) => state.tokenRequired?.tokenError);
  const { t } = useTranslation(['modals']);

  const handleConfirmationCode = (code) => {
    if (code.length === 4) {
      completeCheckout(Number(code));
    }
  };

  const handleCloseModal = () => {
    dispatch({
      type: 'SET_TOKEN_REQUIRED',
      payload: false,
    });
    dispatch({
      type: 'SET_TOKEN_ERROR',
      payload: false,
    });
  };

  return (
    <DialogStyled
      hideBackdrop
      open={tokenRequired}
      onClose={() => handleCloseModal()}
    >
      <DialogContentStyled>
        <Stack direction="column">
          <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2}>
            <Typography variant="h6">
              {t('modal-card-pay-confirmation')}
            </Typography>
            <IconButton onClick={() => handleCloseModal()}>
              <Close />
            </IconButton>
          </Stack>
          <Typography variant="body2">
            {t('modal-card-pay-text-one')}
            <SpanStyled bold="true">
              &nbsp;
              {userEmail}
            </SpanStyled>
            <SpanStyled>
              {t('modal-card-pay-text-two')}
            </SpanStyled>
          </Typography>
        </Stack>
        <AuthCode onChange={handleConfirmationCode} error={false} />
        {loading
          ? <CircularProgress sx={{ alignSelf: 'center' }} size="2rem" />
          : (tokenError && (
            <Typography variant="body2" color="#B12323" sx={{ textAlign: 'center' }}>
              {t('modal-card-pay-invalid-code')}
            </Typography>
          ))}
        <ModalCancelButton
          variant="contained"
          fullWidth
          onClick={() => handleCloseModal()}
        >
          {t('modal-cancel')}
        </ModalCancelButton>
      </DialogContentStyled>
    </DialogStyled>
  );
}

export default PayConfirmationModal;
