/* eslint-disable react/jsx-indent */
import {
  Button,
  Checkbox, Grid, Stack, Typography, styled,
} from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import useTrack from '../../hooks/useTrack';
import { handleNumericRegex } from '../../utils/regex';
import { TextInputStyled } from '../StepsCheckout/stepscheckout.styled';

const NequiCard = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'stretch',
  gap: '1rem',
  padding: '1rem',
  backgroundColor: '#F4F6F8',
  borderRadius: '16px',
}));

function IntegrationNequi({ handleAccordion, formIncomplete }) {
  const dispatch = useDispatch();
  const { t } = useTranslation(['credit-card']);
  const user = useSelector((state) => state.user);
  const order = useSelector((state) => state.orders);
  const checkoutStep = useSelector((state) => state.checkoutStep);
  const paymentMethods = order.store.allowed_payment_methods;
  const nequiMethod = paymentMethods?.find((method) => (method?.gateway_type === 'nequi'));
  const [useContactNumber, setUseContactNumber] = useState(true);

  const addNequiPayment = () => {
    handleAccordion(false);
    dispatch({
      type: 'SET_PAYMENT_METHOD',
      payload: {
        ...nequiMethod,
      },
    });
    dispatch({
      type: 'SET_PREORDER_PAYMENT',
      payload: {
        ...nequiMethod,
      },
    });
    dispatch({
      type: 'SET_MESSAGE',
      payload: { text: t('nequi-save-msg') || 'Nequi phone saved!', kind: 'success' },
    });
    setUseContactNumber(true);
    if (checkoutStep === 3) {
      useTrack('pago_nequi', {
        pantalla: 'Paso 3 - Forma de pago',
        merchant: order?.store?.store_name,
      });
    } else if (checkoutStep === 4) {
      useTrack('pago_nequi', {
        pantalla: 'Paso 4 - Resumen',
        merchant: order?.store?.store_name,
      });
    }
  };

  const handleNequiNumber = (validPhone) => {
    dispatch({
      type: 'SET_USER_DATA',
      payload: { phone_number: validPhone },
    });
  };

  return (
    <NequiCard>
      <Stack direction="row" alignItems="center">
        <Checkbox
          disabled={formIncomplete}
          checked={useContactNumber}
          onClick={() => setUseContactNumber(!useContactNumber)}
        />
        <Typography variant="body1" sx={{ color: 'text.disabled' }}>
          {t('nequi-contact-number')}
        </Typography>
      </Stack>
      <Stack direction="row">
        <TextInputStyled
          fullWidth
          type="text"
          disabled={useContactNumber || formIncomplete}
          label={t('nequi-label')}
          InputLabelProps={{ shrink: true }}
          placeholder={user.phone_number}
          value={user.phone_number}
          onChange={(e) => handleNumericRegex(e.target.value, handleNequiNumber)}
          InputProps={{
            endAdornment:
              <Button
                size="large"
                variant="contained"
                disabled={useContactNumber || formIncomplete}
                sx={{ textTransform: 'none', borderRadius: '100px' }}
                onClick={() => addNequiPayment()}
              >
                {t('nequi-save')}
              </Button>,
          }}
        />
      </Stack>
    </NequiCard>

  );
}

export default IntegrationNequi;
