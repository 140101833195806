/* eslint-disable camelcase */
/* eslint-disable no-unused-expressions */
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : 'https://dev.api.wava.co/';
const override_timeout = 1000000;

export const api = (props) => {
  const {
    url,
    data = {},
    successHandler = (response) => response,
    errorHandler = (error) => {
      console.error('fetch failed', error);
    },
  } = props;
  return axios.post(
    apiUrl + url,
    JSON.stringify(data),
    {
      timeout: override_timeout,
    },
  )
    .then((response) => response.data)
    .then((decodedResponse) => {
      decodedResponse.error
        ? errorHandler(
          decodedResponse?.result?.message
            ? decodedResponse?.result?.message
            : decodedResponse?.result,
        )
        : successHandler(decodedResponse);
    })
    .catch((err) => {
      console.log('err', err);
      errorHandler(err);
    });
};

export const apiGet = (props) => {
  const {
    url,
    successHandler = (response) => response,
    errorHandler = (error) => {
      console.error('fetch failed', error);
    },
  } = props;
  return axios.get(
    apiUrl + url,
  )
    .then((response) => response.data)
    .then((decodedResponse) => {
      decodedResponse.error
        ? errorHandler(
          decodedResponse?.result?.message
            ? decodedResponse?.result?.message
            : decodedResponse?.result,
        )
        : successHandler(decodedResponse);
    })
    .catch((err) => {
      errorHandler(err.data);
    });
};

export const apiPatch = (props) => {
  const {
    url,
    data = {},
    successHandler = (response) => response,
    errorHandler = (error) => {
      console.error('fetch failed', error);
    },
  } = props;
  return axios.patch(
    apiUrl + url,
    JSON.stringify(data),
  )
    .then((response) => response.data)
    .then((decodedResponse) => {
      decodedResponse.error
        ? errorHandler(
          decodedResponse?.result?.message
            ? decodedResponse?.result?.message
            : decodedResponse?.result,
        )
        : successHandler(decodedResponse);
    })
    .catch((err) => {
      console.log('err', err);
      errorHandler(err);
    });
};

export const apiPut = (props) => {
  const {
    url,
    data = {},
    successHandler = (response) => response,
    errorHandler = (error) => {
      console.error('fetch failed', error);
    },
  } = props;
  return axios.put(
    apiUrl + url,
    JSON.stringify(data),
  )
    .then((response) => response.data)
    .then((decodedResponse) => {
      decodedResponse.error
        ? errorHandler(
          decodedResponse?.result?.message
            ? decodedResponse?.result?.message
            : decodedResponse?.result,
        )
        : successHandler(decodedResponse);
    })
    .catch((err) => {
      console.log('err', err);
      errorHandler(err);
    });
};

export const apiDelete = (props) => {
  const {
    url,
    successHandler = (response) => response,
    errorHandler = (error) => {
      console.error('fetch failed', error);
    },
  } = props;
  return axios.delete(
    apiUrl + url,
  )
    .then((response) => response.data)
    .then((decodedResponse) => {
      decodedResponse.error
        ? errorHandler(
          decodedResponse?.result?.message
            ? decodedResponse?.result?.message
            : decodedResponse?.result,
        )
        : successHandler(decodedResponse);
    })
    .catch((err) => {
      console.log('err', err);
      errorHandler(err);
    });
};
