import { Grid, styled } from '@mui/material';

export const OrderCompleteContainer = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'stretch',
  width: '100%',
  gap: '2rem',
  paddingTop: '2rem',
}));

export const ConfirmationContainer = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%',
  gap: '0.5rem',
}));

export const ConfirmationCard = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'stretch',
  width: '100%',
  padding: '2rem',
  gap: '2rem',
  borderRadius: '20px',
  backgroundColor: '#F0F2F1',
}));
