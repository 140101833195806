import { styled } from '@mui/material/styles';
import { Typography, Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import emptyCart from '../assets/illustration_empty_cart.svg';

const RootStyle = styled(Box)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  textAlign: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(8, 2),
}));

function EmptyContent() {
  const inactiveStore = useSelector((state) => state.merchant.inactiveStore);
  const { t } = useTranslation(['guest-checkout']);
  return (
    <RootStyle>
      <img
        src={emptyCart}
        style={{ height: 240, marginBottom: '1.5rem' }}
        alt="empty content"
      />

      <Typography variant="h5" gutterBottom>
        {inactiveStore ? t('inactive-store-title') : t('empty-cart-title')}
      </Typography>
      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
        {inactiveStore ? t('inactive-store-subtitle') : t('empty-cart-subtitle')}
      </Typography>
    </RootStyle>
  );
}

export default EmptyContent;
