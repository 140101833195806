/* eslint-disable prefer-destructuring */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Grid, Divider, useMediaQuery,
} from '@mui/material';
import {
  DataContainer,
  StepsSection,
  StoreLogoContainer,
  StoreLogo,
  CheckoutContainer,
} from './stepscheckout.styled';
import LoadingScreen from '../LoadingScreen';
import CodeStep from './CodeStep';
import Summary from './Summary/Summary';
import NequiConfirmation from '../Nequi/NequiConfirmation';
import { orders } from '../../redux/actions/ordersAction';
import OrderCompleteStep from './OrderCompleteStep/OrderCompleteStep';
import EmptyContent from '../EmptyContent';
import Footer from './Footer/Footer';
import UserPayment from './UserPayment/UserPayment';
import { SummarySection } from './Summary/summary.styled';
import SummaryCardMobile from './Summary/SummaryCardMobile';
import useTrack from '../../hooks/useTrack';
import decodeUTF8 from '../../utils/decodeUTF8';
import wavaLogo from '../../assets/dashboard/logo_wava.svg';
import PaymentForm from './UserPayment/PaymentForm';

function PaymentCheckout() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMediaQuery('(max-width:780px)');
  const { hash } = useParams();
  const { t } = useTranslation(['purchase']);

  const orderData = useSelector((state) => state.orders);
  const user = useSelector((state) => state.user);
  // const paymentMethod = useSelector((state) => state.orders?.payment_method?.id_payment_gateway);
  // const [userReferer, setUserReferer] = useState('');
  const [userDataSaved, setUserDataSaved] = useState(false);
  const [userDataFromParams, setUserDataFromParams] = useState({
    id_store: '',
    email: '',
    phone_number: '',
    first_name: '',
    last_name: '',
    id_national_document_type: '',
    national_id_number: '',
  });

  const [creditCard, setCreditCard] = useState(
    user?.isAuthenticated
      ? user?.payment_methods[0]?.credit_cards?.find((cc) => cc.is_default === 1)
      : false,
  );

  const ccSessionData = JSON.parse(sessionStorage.getItem('cc'));

  const selectedStep = useSelector((state) => state.checkoutStep);

  const paymentMethods = orderData?.store?.allowed_payment_methods;
  const cardMethod = paymentMethods?.find((method) => (method?.gateway_type === 'card'));

  useEffect(() => {
    setCreditCard(
      user?.isAuthenticated
        ? user?.payment_methods[0]?.credit_cards?.find((cc) => cc.is_default === 1)
        : user?.payment_methods[0]?.credit_cards[0],
    );
  }, [user, orderData]);

  const submitCreditCard = (values) => {
    useTrack('pago_tarjeta', {
      pantalla: 'Paso 3 - Forma de pago',
      merchant: orderData?.store?.store_name,
    });
    if (values) {
      dispatch({
        type: 'SET_USER_DATA',
        payload: { payment_methods: [{ credit_cards: [values] }] },
      });
      dispatch({
        type: 'SET_PAYMENT_METHOD',
        payload: {
          ...values,
          ...cardMethod,
          id_credit_card_external: values.id_external,
        },
      });
      dispatch({
        type: 'SET_PREORDER_PAYMENT',
        payload: {
          ...values,
          ...cardMethod,
          id_credit_card_external: values.id_external,
        },
      });
    }
  };

  useEffect(() => {
    if (!orderData?.loadingOrders) {
      if (hash) {
        const decodedHash = atob(hash);
        const params = decodedHash.split('|');
        let productsObject = {
          products: [],
        };
        Promise.all(
          params.map((param) => {
            const currentField = param.split('=');
            switch (currentField[0]) {
              case 'ie':
                productsObject = {
                  ...productsObject,
                  products: currentField[1].split(';').map((product) => {
                    const prodQty = product.split(',');
                    return {
                      id_external: prodQty[0],
                      quantity: prodQty[1] || 1,
                    };
                  }),
                };
                break;
              case 'mixid':
                document.cookie = `mixid=${currentField[1]};max-age=86400;SameSite=None;Secure`;
                break;
              case 'is':
                productsObject = {
                  ...productsObject,
                  id_store: currentField[1],
                };
                userDataFromParams.id_store = currentField[1]?.replace(/ /g, '');
                break;
              case 'ip':
                productsObject = {
                  ...productsObject,
                  products: currentField[1].split(';').map((product) => {
                    const prodQty = product.split(',');
                    return {
                      id_product: prodQty[0],
                      quantity: prodQty[1] || 1,
                    };
                  }),
                };
                break;
              case 'pn':
                productsObject = {
                  ...productsObject,
                  products: [{
                    ...productsObject.products[0],
                  }, {
                    ...productsObject.products[1],
                    name: currentField[1],
                  }],
                };
                break;
              case 'a':
                productsObject = {
                  ...productsObject,
                  products: [{
                    ...productsObject.products[0],
                  }, {
                    ...productsObject.products[1],
                    amount: currentField[1]?.replace(/ /g, ''),
                  }],
                };
                break;
              case 't':
                productsObject = {
                  ...productsObject,
                  tax: currentField[1],
                };
                break;
              case 'm':
                productsObject = {
                  ...productsObject,
                  email: currentField[1],
                };
                userDataFromParams.email = currentField[1]?.replace(/ /g, '');
                break;
              case 'p':
                productsObject = {
                  ...productsObject,
                  phone: currentField[1],
                };
                userDataFromParams.phone_number = currentField[1]?.replace(/ /g, '');
                break;
              case 'fn':
                productsObject = {
                  ...productsObject,
                  first_name: decodeUTF8(currentField[1]),
                };
                userDataFromParams.first_name = decodeUTF8(currentField[1]);
                break;
              case 'ln':
                productsObject = {
                  ...productsObject,
                  last_name: decodeUTF8(currentField[1]),
                };
                userDataFromParams.last_name = decodeUTF8(currentField[1]);
                break;
              case 'dt':
                productsObject = {
                  ...productsObject,
                  id_national_document_type: currentField[1],
                };
                userDataFromParams.id_national_document_type = currentField[1]?.replace(/ /g, '');
                break;
              case 'dn':
                productsObject = {
                  ...productsObject,
                  national_id_number: currentField[1],
                };
                userDataFromParams.national_id_number = currentField[1]?.replace(/ /g, '');
                break;
              case 'r':
                // setUserReferer(currentField[1]);
                break;
              case 'c':
                productsObject = {
                  ...productsObject,
                  coupons: (currentField[1].split(';')).map((couponCode) => couponCode),
                };
                break;
              case 'ko':
                productsObject = {
                  ...productsObject,
                  order_key: currentField[1]?.replace(/ /g, ''),
                };
                break;
              case 'l':
                productsObject = {
                  ...productsObject,
                  redirect_link: currentField[1]?.replace(/ /g, ''),
                };
                dispatch({
                  type: 'SET_REDIRECT_LINK',
                  payload: currentField[1],
                });
                break;
              default:
                break;
            }
            return true;
          }),
        ).then(() => {
          if (productsObject.products.length > 1) {
            if (Object?.keys(productsObject?.products[1])?.length === 2) {
              dispatch(orders({
                ...productsObject,
                products: [productsObject.products[1]],
              }, { msg_success: t('msg_success') }, navigate));
            } else {
              dispatch(orders({
                ...productsObject,
                products: [productsObject.products[0]],
              }, { msg_success: t('msg_success') }, navigate));
            }
          } else {
            dispatch(orders({
              ...productsObject,
              products: [productsObject.products[0]],
            }, { msg_success: t('msg_success') }, navigate));
          }
        });
      } else {
        const queryParams = Object.fromEntries(new URLSearchParams(location?.search));
        if (Object.keys(queryParams).length > 0) {
          if (queryParams.mixid) {
            document.cookie = `mixid=${queryParams.mixid};max-age=86400;SameSite=None;Secure`;
          }
          // setUserReferer(queryParams.r || false);
          dispatch({
            type: 'SET_REDIRECT_LINK',
            payload: (queryParams.l || queryParams.redirect_link) || '',
          });
          setUserDataFromParams({
            ...userDataFromParams,
            email: queryParams.m?.replace(/ /g, '') || '',
            phone_number: queryParams.p?.replace(/ /g, '') || '',
            first_name: decodeUTF8(queryParams.fn) || '',
            last_name: decodeUTF8(queryParams.ln) || '',
            id_store: queryParams.is || '',
            id_national_document_type: queryParams.dt || '',
            national_id_number: queryParams.dn?.replace(/ /g, '') || '',
          });
          if ((queryParams.pn || queryParams.name) && (queryParams.a || queryParams.amount)) {
            dispatch(orders({
              products: [{
                amount: queryParams.a || queryParams.amount,
                name: queryParams.pn || queryParams.name,
              }],
              order_key: queryParams.ko || queryParams.order_key,
              id_store: queryParams.is || queryParams.id_store,
              tax: queryParams.t || queryParams.tax,
              email: queryParams.m?.replace(/ /g, '') || queryParams.email?.replace(/ /g, ''),
              phone: queryParams.p?.replace(/ /g, '') || queryParams.phone?.replace(/ /g, ''),
              coupons: queryParams.c && (queryParams.c.split(';')).map((couponCode) => couponCode),
              redirect_link: queryParams.l || queryParams.redirect_link,
            }, { msg_success: t('msg_success') }, navigate));
          } else {
            dispatch(orders({
              products: [{
                id_external: queryParams.ie || queryParams.id_external,
                id_product: queryParams.ip || queryParams.id_product,
                quantity: queryParams.q || queryParams.quantity,
              }],
              order_key: queryParams.ko || queryParams.order_key,
              id_store: queryParams.is || queryParams.id_store,
              tax: queryParams.t || queryParams.tax,
              email: queryParams.m?.replace(/ /g, '') || queryParams.email?.replace(/ /g, ''),
              phone: queryParams.p?.replace(/ /g, '') || queryParams.phone?.replace(/ /g, ''),
              coupons: queryParams.c && (queryParams.c.split(';')).map((couponCode) => couponCode),
              redirect_link: queryParams.l || queryParams.redirect_link,
            }, { msg_success: t('msg_success') }, navigate));
          }
        }
      }
    }
    dispatch({
      type: 'SET_CHECKOUT_STEP',
      payload: 3,
    });
    if (!orderData?.store && !user.isAuthenticated) {
      localStorage.removeItem('state');
      sessionStorage.removeItem('state');
      dispatch({
        type: 'SET_CHECKOUT_STEP',
        payload: 3,
      });
      dispatch({
        type: 'RESET_USER',
      });
    }
    if (ccSessionData) {
      submitCreditCard(ccSessionData);
    }
  }, [user.isAuthenticated]);

  return (
    orderData?.loadingOrders
      ? <LoadingScreen />
      : (
        <CheckoutContainer>
          {orderData?.store
            ? (
              <Grid
                container
                maxWidth="lg"
                sx={{
                  display: 'flex',
                  flexDirection: isMobile ? 'column' : 'row',
                  paddingLeft: isMobile ? '16px' : '2rem',
                  paddingRight: isMobile ? '16px' : '2rem',
                  gap: isMobile ? '1rem' : '3rem',
                  height: isMobile ? 'auto' : '100%',
                  minHeight: isMobile ? 'auto' : '820px',
                }}
              >
                <StepsSection mt={isMobile ? 2 : 4} mb={isMobile ? 0 : 8}>
                  <StoreLogoContainer mobile={isMobile ? 1 : 0}>
                    {orderData?.store?.logo_image
                      ? <StoreLogo src={orderData?.store?.logo_image} alt="Store Logo" />
                      : <StoreLogo src={wavaLogo} alt="Wava logo" />}
                  </StoreLogoContainer>
                  {isMobile && <SummaryCardMobile paymentCheckout />}
                  {selectedStep === 0 && <CodeStep />}

                  <DataContainer sx={{ gap: '1rem' }}>
                    {selectedStep === 3 && (
                      <PaymentForm
                        paramsData={userDataFromParams}
                        emptyCart={!orderData?.store}
                        userDataSaved={userDataSaved}
                        setUserDataSaved={setUserDataSaved}
                      />
                    )}
                    {selectedStep === 3
                      && (
                        <UserPayment
                          stepTitle={t('third-step-title')}
                          defaultPayment={creditCard || ccSessionData}
                          paymentMethods={orderData?.store?.allowed_payment_methods}
                          submitCreditCard={submitCreditCard}
                          prevStep={!orderData.isDigital ? t('second-step') : null}
                          formIncomplete={!userDataSaved}
                          sessionData={Boolean(ccSessionData)}
                        />
                      )}
                    {selectedStep === 5 && <OrderCompleteStep />}
                    {selectedStep === 6 && <NequiConfirmation />}
                  </DataContainer>
                </StepsSection>

                <Grid sx={isMobile ? { display: 'none' } : { display: 'flex' }}>
                  <Divider orientation="vertical" />
                </Grid>

                <SummarySection>
                  <Summary currentStep={selectedStep} paymentCheckout />
                </SummarySection>
              </Grid>
            )
            : (
              <EmptyContent />
            )}
          <Footer />
        </CheckoutContainer>
      )
  );
}

export default PaymentCheckout;
